import { Datagrid, List, TextField } from 'react-admin';

const DisplaySurfaceList = () => (
  <List perPage={25}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

export default DisplaySurfaceList;
