import Protect from 'components/Protect';
import { AutocompleteInput, FormTab, FormTabProps, useGetList } from 'react-admin';
import { useTabbedFormTabHandler } from '../useTabbedFormTabHandler';

export const UnitEditMobileNetwork = (props: FormTabProps) => {
  useTabbedFormTabHandler(props);
  // @ts-ignore
  const { data: permissions, isLoading } = useGetList('permissions/mobile_network', {
    pagination: {
      page: 1,
      perPage: 1000,
    },
  });

  return (
    <Protect permissions={['dev_options', 'manage_units']}>
      <FormTab {...props}>
        <AutocompleteInput
          fullWidth={true}
          source="mobile_network.permissions"
          choices={permissions}
          multiple={true}
          optionText="name"
          optionValue="name"
          isLoading={isLoading}
        />
      </FormTab>
    </Protect>
  );
};
