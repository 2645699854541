import { FC } from 'react';
import { Create, CreateProps, required, SimpleForm, TextInput } from 'react-admin';

const UnitCreate: FC<CreateProps> = () => {
  return (
    <Create redirect="edit">
      <SimpleForm>
        <TextInput source="serial" resettable validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default UnitCreate;
