import { FC, useEffect, useState } from 'react';
import { dataProvider } from '../../providers';

const ToverviewVersionField: FC = () => {
  const [apiVersion, setApiVersion] = useState({ version: 'v1.2.3', link: 'https://gitlab.com' });

  useEffect(() => {
    dataProvider
      .getOne('git', { id: 'version' })
      .then((response: any) => {
        setApiVersion(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <a style={{ textDecoration: 'none' }} target="_blank" href={apiVersion.link} rel="noreferrer">
      API {apiVersion.version}
    </a>
  );
};

export default ToverviewVersionField;
