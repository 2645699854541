import { Alert, CardContent } from '@mui/material';
import { CustomTabbedFormProvider, useCustomTabbedForm } from 'components/TabbedForm';
import { ShowTabButton } from 'components/TabButtons/ToTabButtons';
import { FC, useEffect } from 'react';
import {
  Edit,
  EditProps,
  TabbedForm,
  TopToolbar,
  useEditController,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UnitEditApps, UnitEditConfig, UnitEditDev, UnitEditInfo } from './components';
import { UnitEditMobileNetwork } from './components/UnitEditMobileNetwork';

const UnitActions = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <>
      <TopToolbar>
        <ShowTabButton />
      </TopToolbar>

      {record?.developer_options?.staging_api && (
        <Alert variant="filled" severity="warning" sx={{ mb: 2 }}>
          {translate('resources.units.uses_staging_api')}
        </Alert>
      )}
    </>
  );
};
const UnitEdit: FC<EditProps> = (props) => {
  const { id } = useParams();
  const { isLoading } = useEditController({ resource: 'units', id });

  if (isLoading) return null;

  return (
    <Edit mutationMode="pessimistic" {...props} redirect={false} actions={<UnitActions />}>
      <CustomTabbedFormProvider>
        <UnitEditTabs />
      </CustomTabbedFormProvider>
    </Edit>
  );
};

const UnitEditTabs = (props: any) => {
  const { handleSubmit } = useCustomTabbedForm();
  const navigate = useNavigate();
  const currentPath = useLocation();

  useEffect(() => {
    if (!/units\/\d+\/[a-z]+/.test(currentPath.pathname)) {
      navigate(currentPath.pathname + '/info');
    }
  }, [currentPath.pathname, navigate]);

  return (
    <TabbedForm {...props} onSubmit={handleSubmit} component={DefaultComponent} warnWhenUnsavedChanges>
      <UnitEditInfo label="resources.units.tabs.info" path="info" value="info" />
      <UnitEditConfig label="resources.units.tabs.configuration" path="configuration" value="configuration" />
      <UnitEditApps label="resources.units.tabs.apps" path="apps" value="apps" />
      <UnitEditDev label="resources.units.tabs.developer_options" path="developer_options" value="developer_options" />
      <UnitEditMobileNetwork label="resources.units.tabs.mobile_network" path="mobile_network" value="mobile_network" />
    </TabbedForm>
  );
};

const DefaultComponent = ({ children }: any) => {
  return <CardContent>{children}</CardContent>;
};

export default UnitEdit;
