import {
  BooleanField,
  FunctionField,
  Labeled,
  Link,
  RaRecord,
  Tab,
  TabProps,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { Grid, List, ListItem, Typography } from '@mui/material';
import { FormattedDateField } from 'components/FormattedDateField';
import { State } from 'constants/State';
import { get } from 'lodash';
import { BinarySizeField } from './BinarySizeField';
import { SimCardProviderField } from './SimCardProviderField';

const InfoSection = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const simProvider = get(record, 'mobile_network.provider');
  const simId = get(record, 'mobile_network.sim_id');

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4">{translate('resources.units.section.simcard_info')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Labeled>
          <SimCardProviderField source="mobile_network.provider" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Labeled>
          <FunctionField
            label="resources.units.fields.mobile_network.sim_id"
            render={(record: RaRecord) =>
              simId && simProvider === 'onomondo' ? (
                <Link to={`${process.env.REACT_APP_ONOMONDO_APP}/sims/${record.mobile_network.sim_id}`} target="_blank">
                  {record.mobile_network.sim_id}
                </Link>
              ) : (
                simId
              )
            }
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Labeled>
          <FunctionField
            label="resources.units.fields.mobile_network.state"
            render={(record: RaRecord) =>
              record.mobile_network.state ? (
                <span>
                  {translate(`resources.units.fields.mobile_network.states.${State[record.mobile_network.state]}`)}
                </span>
              ) : (
                '-'
              )
            }
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Labeled>
          <BooleanField source="mobile_network.is_lte_enabled" emptyText="-" />
        </Labeled>
      </Grid>
    </Grid>
  );
};

const DataSection = () => {
  const record = useRecordContext();
  const simProvider = get(record, 'mobile_network.provider');
  const isBinary = simProvider !== 'onomondo';

  const translate = useTranslate();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4">{translate('resources.units.section.simcard_data')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Labeled>
          <BinarySizeField source="mobile_network.alert_threshold" emptyText="-" isBinary={isBinary} />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Labeled>
          <BinarySizeField source="mobile_network.bandwidth_usage" emptyText="-" isBinary={isBinary} />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Labeled>
          <BinarySizeField source="mobile_network.bandwidth_limit" emptyText="-" isBinary={isBinary} />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Labeled>
          <FormattedDateField source="mobile_network.next_reset_time" emptyText="-" />
        </Labeled>
      </Grid>
    </Grid>
  );
};

const PermissionSection = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) {
    return null;
  }

  return (
    <>
      <Typography variant="h4">{translate('resources.units.section.mobile_permissions', 'Permissions')}</Typography>
      <List>
        {record.mobile_network?.permissions?.map((item: string) => <ListItem key={item}>{item}</ListItem>) ?? (
          <ListItem>-</ListItem>
        )}
      </List>
    </>
  );
};
export const MobileNetwork = (props: Omit<TabProps, 'children'>) => {
  const record = useRecordContext();
  const simId = get(record, 'mobile_network.sim_id');
  const hardwareId = get(record, 'hardware_id');

  return hardwareId >= 2 && simId ? (
    <Tab {...props}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InfoSection />
        </Grid>
        <Grid item xs={12}>
          <DataSection />
        </Grid>
        <Grid item xs={12}>
          <PermissionSection />
        </Grid>
      </Grid>
    </Tab>
  ) : null;
};
