// src/components/DateRangeModal.tsx
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { startOfMonth, subMonths } from 'date-fns';
import { FC, useState } from 'react';
import { Button, DateInput, Form, required, useListContext, useTranslate } from 'react-admin';

interface DateRangeModalProps {
  open: boolean;
  onClose: () => void;
  onDownload: (startDate: Date, endDate: Date, type: string, selectedIds: string[]) => Promise<void>;
  types: string[];
}

const DateRangeModal: FC<DateRangeModalProps> = ({ open, onClose, onDownload, types }) => {
  const translate = useTranslate();
  const defaultEndDate = startOfMonth(new Date());
  const defaultStartDate = subMonths(defaultEndDate, 6);
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);
  const { selectedIds } = useListContext();

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value ? new Date(e.target.value) : defaultStartDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value ? new Date(e.target.value) : defaultEndDate);
  };

  const handleDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await onDownload(startDate, endDate, e.currentTarget.getAttribute('data-type') as string, selectedIds).finally(
      onClose,
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{translate('resources.units.reports.period')}</DialogTitle>
      <DialogContent>
        <Box>
          <Form>
            <Grid container spacing={2}>
              <Grid size={6}>
                <DateInput
                  source="startDate"
                  label="resources.units.reports.start_date"
                  onChange={handleStartDateChange}
                  validate={required()}
                  defaultValue={defaultStartDate}
                />
              </Grid>
              <Grid size={6}>
                <DateInput
                  source="endDate"
                  label="resources.units.reports.end_date"
                  onChange={handleEndDateChange}
                  validate={required()}
                  defaultValue={defaultEndDate}
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} label="ra.action.cancel" />
        {types.map((type) => (
          <Button
            key={`units.reports.download.${type}`}
            onClick={handleDownload}
            label={'resources.units.reports.download.' + type}
            variant="contained"
            data-type={type}
          />
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default DateRangeModal;
