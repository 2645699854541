import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { startOfMonth, subMonths } from 'date-fns';
import { useState } from 'react';
import { Button, DateInput, Form, required, Tab, TabProps, Toolbar, useRecordContext, useTranslate } from 'react-admin';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useReportDownloadHandler } from '../useReportDownloadHandler';

const queryClient = new QueryClient();

export const ReportsTab = (props: Omit<TabProps, 'children'>) => {
  const record = useRecordContext();
  const translate = useTranslate();
  // Calculate default dates
  const onDownload = useReportDownloadHandler();

  const defaultEndDate = startOfMonth(new Date());
  const defaultStartDate = subMonths(defaultEndDate, 6);
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value ? new Date(e.target.value) : defaultStartDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value ? new Date(e.target.value) : defaultEndDate);
  };

  const handleDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await onDownload(startDate, endDate, e.currentTarget.getAttribute('data-type') as string, [
      (record?.id || '') as string,
    ]);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Tab {...props}>
        <h3>{translate('resources.units.reports.period')}</h3>
        <Box sx={{ width: '50%' }}>
          <Form>
            <Grid container spacing={2}>
              <Grid size={6}>
                <DateInput
                  source="startDate"
                  label="resources.units.reports.start_date"
                  onChange={handleStartDateChange}
                  validate={required()}
                  defaultValue={defaultStartDate}
                />
              </Grid>
              <Grid size={6}>
                <DateInput
                  source="endDate"
                  label="resources.units.reports.end_date"
                  onChange={handleEndDateChange}
                  validate={required()}
                  defaultValue={defaultEndDate}
                />
              </Grid>
            </Grid>
            <Toolbar>
              {['eventlog', 'game_played'].map((type) => (
                <Button
                  onClick={handleDownload}
                  label={'resources.units.reports.download.' + type}
                  variant="contained"
                  data-type={type}
                />
              ))}
            </Toolbar>
          </Form>
        </Box>
      </Tab>
    </QueryClientProvider>
  );
};

ReportsTab.defaultProps = {
  label: 'resources.units.tabs.reports',
  value: 'reports',
  path: 'reports',
};
