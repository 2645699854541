import { isAxiosError } from 'axios';
import { format } from 'date-fns';
import { stringify } from 'query-string';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';

export function useReportDownloadHandler() {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  return async (startDate: Date, endDate: Date, type: string, selectedIds: string[]) => {
    try {
      const formattedStartDate = format(startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');

      const queryParams = {
        unit_ids: selectedIds,
        report_type: type,
        report_period: 'week',
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
      const queryString = stringify(queryParams, { arrayFormat: 'bracket' });
      const url = `reports/download/usagelog?${queryString}`;

      notify('custom.notification.download_started', {
        type: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        messageArgs: { item: translate('resources.units.reports.types.' + type) },
      });
      await dataProvider.download(url);
      notify('custom.notification.download', {
        type: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        messageArgs: { item: translate('resources.units.reports.types.' + type) },
      });
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        switch (error.response.status) {
          case 404:
            notify('custom.notification.error.not_found', {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              messageArgs: { item: translate('resources.units.show.links.usagelog') },
              type: 'error',
            });
            break;
          default:
            notify('ra.page.error', { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      }
    }
  };
}
