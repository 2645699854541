import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

export const setupEcho = async (token: string) => {
  console.log('setup echo', token);

  // @ts-ignore
  if (window.Pusher === undefined) {
    console.log('setting up pusher');
    // @ts-ignore
    window.Pusher = Pusher;
  }

  // @ts-ignore
  if (window.Echo === undefined) {
    console.log('setting up echo');
    // @ts-ignore
    window.Echo = new Echo({
      broadcaster: 'reverb',
      key: process.env.REACT_APP_REVERB_APP_KEY,

      auth: {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        params: {
          client_id: process.env.REACT_APP_REVERB_APP_ID,
          client_secret: process.env.REACT_APP_REVERB_APP_SECRET,
        },
      },
      namespace: 'Tover.Events',
      wsHost: process.env.REACT_APP_REVERB_HOST,
      wsPort: process.env.REACT_APP_REVERB_PORT,
      wssPort: process.env.REACT_APP_REVERB_PORT,
      forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? 'https') === 'https',
      enabledTransports: ['ws', 'wss'],
      authEndpoint: process.env.REACT_APP_REVERB_SERVER_URL + '/broadcasting/auth',
    });
  }
};
