// Usage: import dutchMessages from './Languages/ra-language-dutch';
// Usage: <Admin locale="nl" i18nProvider={i18nProvider} dataProvider={dataProvider}>
// This is the copy of the original file from the ra-language-dutch package
// The original file is not working properly, so I copied it here and fixed it
// The original file is located at node_modules/ra-language-dutch/src/index.ts
import { TranslationMessages } from 'ra-core';

const dutchMessages: TranslationMessages = {
  ra: {
    action: {
      add_filter: 'Filter toevoegen',
      add: 'Toevoegen',
      back: 'Terug',
      bulk_actions: '1 item geselecteerd |||| %{smart_count} items geselecteerd',
      cancel: 'Annuleren',
      clear_array_input: 'Lijst leegmaken',
      clear_input_value: 'Waarde wissen',
      clone: 'Klonen',
      confirm: 'Bevestigen',
      create: 'Aanmaken',
      create_item: '%{item} aanmaken',
      delete: 'Verwijderen',
      edit: 'Bewerken',
      export: 'Exporteren',
      list: 'Lijst',
      refresh: 'Vernieuwen',
      remove_filter: 'Dit filter verwijderen',
      remove_all_filters: 'Alle filters verwijderen',
      remove: 'Verwijderen',
      save: 'Opslaan',
      search: 'Zoeken',
      select_all: 'Alles selecteren',
      select_all_button: 'Alles selecteren',
      select_row: 'Selecteer deze rij',
      show: 'Tonen',
      sort: 'Sorteren',
      undo: 'Ongedaan maken',
      unselect: 'Deselecteren',
      expand: 'Uitklappen',
      close: 'Sluiten',
      open_menu: 'Menu openen',
      close_menu: 'Menu sluiten',
      update: 'Bijwerken',
      move_up: 'Omhoog verplaatsen',
      move_down: 'Omlaag verplaatsen',
      open: 'Openen',
      toggle_theme: 'Wissel tussen licht/donker thema',
      select_columns: 'Kolommen',
      update_application: 'Applicatie verversen',
    },
    boolean: {
      true: 'Ja',
      false: 'Nee',
      null: ' ',
    },
    page: {
      create: '%{name} aanmaken',
      dashboard: 'Dashboard',
      edit: '%{name} %{recordRepresentation}',
      error: 'Er is iets misgegaan',
      list: '%{name}',
      loading: 'Laden',
      not_found: 'Niet gevonden',
      show: '%{name} %{recordRepresentation}',
      empty: 'Nog geen %{name}.',
      invite: 'Wilt u er een toevoegen?',
      access_denied: 'Toegang geweigerd',
      authentication_error: 'Authenticatiefout',
    },
    input: {
      file: {
        upload_several: 'Sleep bestanden om te uploaden, of klik om er een te selecteren.',
        upload_single: 'Sleep een bestand om te uploaden, of klik om het te selecteren.',
      },
      image: {
        upload_several: 'Sleep afbeeldingen om te uploaden, of klik om er een te selecteren.',
        upload_single: 'Sleep een afbeelding om te uploaden, of klik om het te selecteren.',
      },
      references: {
        all_missing: 'Kan referentiegegevens niet vinden.',
        many_missing: 'Ten minste één van de bijbehorende referenties is niet meer beschikbaar.',
        single_missing: 'Bijbehorende referentie is niet meer beschikbaar.',
      },
      password: {
        toggle_visible: 'Wachtwoord verbergen',
        toggle_hidden: 'Wachtwoord tonen',
      },
    },
    message: {
      about: 'Over',
      access_denied: 'U heeft geen rechten om deze pagina te bekijken',
      are_you_sure: 'Weet u het zeker?',
      authentication_error: 'De authenticatieserver gaf een fout en uw inloggegevens konden niet worden gecontroleerd.',
      auth_error: 'Er is een fout opgetreden tijdens het valideren van het authenticatietoken.',
      bulk_delete_content:
        'Weet u zeker dat u dit %{name} wilt verwijderen? |||| Weet u zeker dat u deze %{smart_count} items wilt verwijderen?',
      bulk_delete_title: '%{name} verwijderen |||| %{smart_count} %{name} verwijderen',
      bulk_update_content:
        'Weet u zeker dat u dit %{name} wilt bijwerken? |||| Weet u zeker dat u deze %{smart_count} items wilt bijwerken?',
      bulk_update_title: '%{name} bijwerken |||| %{smart_count} %{name} bijwerken',
      clear_array_input: 'Weet u zeker dat u de hele lijst wilt leegmaken?',
      delete_content: 'Weet u zeker dat u dit item wilt verwijderen?',
      delete_title: '%{name} #%{id} verwijderen',
      details: 'Details',
      error: 'Er is een clientfout opgetreden en uw verzoek kon niet worden voltooid.',
      invalid_form: 'Het formulier is niet geldig. Controleer op fouten',
      loading: 'Even geduld a.u.b.',
      no: 'Nee',
      not_found: 'U heeft een verkeerde URL ingetypt of een ongeldige link gevolgd.',
      select_all_limit_reached:
        'Er zijn te veel elementen om allemaal te selecteren. Alleen de eerste %{max} elementen zijn geselecteerd.',
      unsaved_changes: 'Niet alle wijzigingen zijn opgeslagen. Weet u zeker dat u ze wilt negeren?',
      yes: 'Ja',
    },
    navigation: {
      clear_filters: 'Filters wissen',
      no_filtered_results: 'Geen %{name} gevonden met de huidige filters.',
      no_results: 'Geen %{name} gevonden',
      no_more_results: 'Paginanummer %{page} is buiten de grenzen. Probeer de vorige pagina.',
      page_out_of_boundaries: 'Paginanummer %{page} is buiten de grenzen',
      page_out_from_end: 'Kan niet na de laatste pagina gaan',
      page_out_from_begin: 'Kan niet voor pagina 1 gaan',
      page_range_info: '%{offsetBegin}-%{offsetEnd} van %{total}',
      partial_page_range_info: '%{offsetBegin}-%{offsetEnd} van meer dan %{offsetEnd}',
      current_page: 'Pagina %{page}',
      page: 'Ga naar pagina %{page}',
      first: 'Ga naar eerste pagina',
      last: 'Ga naar laatste pagina',
      next: 'Volgende pagina',
      previous: 'Vorige pagina',
      page_rows_per_page: 'Rijen per pagina:',
      skip_nav: 'Doorgaan naar inhoud',
    },
    sort: {
      sort_by: 'Sorteren op %{field_lower_first} %{order}',
      ASC: 'oplopend',
      DESC: 'aflopend',
    },
    auth: {
      auth_check_error: 'Log in om verder te gaan',
      user_menu: 'Profiel',
      username: 'Gebruikersnaam',
      password: 'Wachtwoord',
      sign_in: 'Inloggen',
      sign_in_error: 'Authenticatie mislukt, probeer opnieuw',
      logout: 'Uitloggen',
    },
    notification: {
      updated: 'Element bijgewerkt |||| %{smart_count} elementen bijgewerkt',
      created: 'Element aangemaakt',
      deleted: 'Element verwijderd |||| %{smart_count} elementen verwijderd',
      bad_item: 'Onjuist element',
      item_doesnt_exist: 'Element bestaat niet',
      http_error: 'Communicatiefout met server',
      data_provider_error: 'dataProvider-fout. Zie console voor details.',
      i18n_error: 'Kan vertalingen voor de opgegeven taal niet laden',
      canceled: 'Actie geannuleerd',
      logged_out: 'Uw sessie is beëindigd, log opnieuw in.',
      not_authorized: 'U bent niet geautoriseerd om deze bron te openen.',
      application_update_available: 'Er is een nieuwe versie beschikbaar.',
    },
    validation: {
      required: 'Verplicht',
      minLength: 'Moet minimaal %{min} tekens bevatten',
      maxLength: 'Mag maximaal %{max} tekens bevatten',
      minValue: 'Moet minimaal %{min} zijn',
      maxValue: 'Mag maximaal %{max} zijn',
      number: 'Moet een getal zijn',
      email: 'Moet een geldig e-mailadres zijn',
      oneOf: 'Moet een van de volgende zijn: %{options}',
      regex: 'Moet voldoen aan het formaat (regexp): %{pattern}',
      unique: 'Moet uniek zijn',
    },
    saved_queries: {
      label: 'Opgeslagen zoekopdrachten',
      query_name: 'Zoekopdrachtnaam',
      new_label: 'Huidige zoekopdracht opslaan...',
      new_dialog_title: 'Huidige zoekopdracht opslaan als',
      remove_label: 'Opgeslagen zoekopdracht verwijderen',
      remove_label_with_name: 'Zoekopdracht "%{name}" verwijderen',
      remove_dialog_title: 'Opgeslagen zoekopdracht verwijderen?',
      remove_message: 'Weet u zeker dat u deze zoekopdracht wilt verwijderen?',
      help: 'Filter de lijst en sla deze zoekopdracht op voor later',
    },
    configurable: {
      customize: 'Aanpassen',
      configureMode: 'Deze pagina configureren',
      inspector: {
        title: 'Inspector',
        content: 'Beweeg over UI-elementen om ze te configureren',
        reset: 'Instellingen resetten',
        hideAll: 'Alles verbergen',
        showAll: 'Alles tonen',
      },
      Datagrid: {
        title: 'Gegevensrooster',
        unlabeled: 'Kolom zonder label #%{column}',
      },
      SimpleForm: {
        title: 'Formulier',
        unlabeled: 'Invoer zonder label #%{input}',
      },
      SimpleList: {
        title: 'Lijst',
        primaryText: 'Primaire tekst',
        secondaryText: 'Secundaire tekst',
        tertiaryText: 'Tertiaire tekst',
      },
    },
  },
};

export default dutchMessages;
