import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  BooleanInput,
  EditBase,
  EditProps,
  SimpleForm,
  TextField,
  Title,
  useEditContext,
  useTranslate,
} from 'react-admin';

import { FormToolbar } from 'components/FormToolbar';
import RegionField from './components/RegionFiled';

export const CountryEdit: FC<EditProps> = () => {
  return (
    <EditBase>
      <CountryEditView />
    </EditBase>
  );
};

const CountryEditView = (props: any) => {
  const { title } = props;
  const { resource, defaultTitle } = useEditContext();
  const translate = useTranslate();

  return (
    <>
      <Title title={title} defaultTitle={defaultTitle} preferenceKey={`${resource}.edit.title`} />
      <Stack>
        <SimpleForm toolbar={<FormToolbar />} component={Box}>
          <Grid container flexDirection="row">
            <Grid container spacing={4} justifyContent="flex-end" sx={{ mt: 4, zIndex: 1 }} />
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  {translate('resources.countries.tabs.general')}
                </Typography>
                <Paper sx={{ p: 2 }}>
                  <TextField source="name" />
                  <BooleanInput source="active" helperText="" />
                </Paper>
              </Grid>
              <RegionField />
            </Grid>
          </Grid>
        </SimpleForm>
      </Stack>
    </>
  );
};
