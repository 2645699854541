import CreateDialog from 'components/CreateDialog';
import EditDialog from 'components/EditDialog';
import React, { Children, ReactElement, useContext } from 'react';
import { useRecordContext, useResourceContext } from 'react-admin';
import DialogContext from './DialogContext';
import DialogContextProvider from './DialogContextProvider';

interface DialogContainerProps {
  children: React.ReactNode;
  createForm: ReactElement;
  editForm?: ReactElement;
  resource?: string;
}

interface DialogContainerViewProps {
  children: React.ReactNode;
  resource?: string;
}

const DialogContainerView = (props: DialogContainerViewProps) => {
  const { isEditing, isCreating, createForm, editForm } = useContext(DialogContext);
  const record = useRecordContext(props);
  const formProps = { ...props, record };
  return (
    <>
      {Children.only(props.children)}
      <CreateDialog open={!!isCreating} {...formProps}>
        {createForm && React.cloneElement(createForm, formProps)}
      </CreateDialog>
      {editForm && (
        <EditDialog open={!!isEditing} {...formProps}>
          {editForm && React.cloneElement(editForm, formProps)}
        </EditDialog>
      )}
    </>
  );
};

const DialogContainer = (props: DialogContainerProps) => {
  const resource = useResourceContext(props);

  return (
    <DialogContextProvider createForm={props.createForm} editForm={props.editForm} resource={resource?.toString()}>
      <DialogContainerView {...props} />
    </DialogContextProvider>
  );
};

export default DialogContainer;
