import { get } from 'lodash';
import { ChipField, ChipFieldProps, FieldProps, useRecordContext } from 'react-admin';

interface ResponseStatusFieldProps extends FieldProps {
  label?: string;
  sortable?: boolean;
}

const ResponseStatusField = ({ label = 'Name', sortable = false, ...props }: ResponseStatusFieldProps) => {
  const record = useRecordContext(props);
  const status = get(record, 'content.response_status');
  let color = 'error';

  switch (true) {
    case status < 300:
      color = 'success';
      break;
    case status >= 300 && status < 400:
      color = 'info';
      break;
    case status >= 400 && status < 500:
      color = 'warning';
      break;
    case status >= 500:
      color = 'error';
      break;
    default:
      // @ts-ignore
      color = undefined;
  }
  if (status === undefined) return null;
  const chipProps = { source: 'content.response_status', color } as ChipFieldProps;
  return <ChipField {...chipProps} />;
};

export default ResponseStatusField;
