import { useRecordContext } from 'react-admin';
import { get as getValue } from 'lodash';
import { Typography } from '@mui/material';

// in miliseconds
const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const rtf = new Intl.RelativeTimeFormat();

const getRelativeTime = (d1: Date, d2 = new Date()) => {
  const elapsed = d1.getTime() - d2.getTime();

  // "Math.abs" accounts for both "past" & "future" scenarios
  // @ts-ignore
  for (var u in units) {
    // @ts-ignore
    if (Math.abs(elapsed) > units[u] || u === 'second') {
      // @ts-ignore
      return rtf.format(Math.round(elapsed / units[u]), u);
    }
  }
};

const RelativeTimeField = ({ source }: any) => {
  const record = useRecordContext();
  const value = getValue(record, source, '');
  if (!value) {
    return null;
  }
  const relativeTime = getRelativeTime(new Date(value));
  return (
    <span title={value}>
      <Typography variant="caption">{relativeTime}</Typography>
    </span>
  );
};
export default RelativeTimeField;
