import { Datagrid, DateField, List, SortPayload, Tab, TabProps, TextField, useRecordContext } from 'react-admin';
import { MorphField } from '../../../../components/Fields/MorphField';

const PropertiesPanel = () => {
  const record = useRecordContext();
  if (!record?.properties) {
    return null;
  }
  return (
    <table>
      {Object.keys(record.properties.old || record.properties.attributes)
        .sort((a, b) => a.localeCompare(b))
        .map((key) => (
          <tr key={key}>
            <td>
              <strong>{key}:</strong>
            </td>
            <td>{record.properties.old?.[key] ? JSON.stringify(record.properties.old[key]) : ''}</td>
            <td>=&gt;</td>
            <td>{JSON.stringify(record.properties.attributes[key])}</td>
          </tr>
        ))}
    </table>
  );
};

const sort: SortPayload = { field: 'created_at', order: 'DESC' };

const ConfigurationChangesSection = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <List resource={'unit/' + record.configuration_key + '/activity_logs'} sort={sort}>
      <Datagrid bulkActionButtons={false} expand={<PropertiesPanel />} isRowExpandable={(row) => row.properties}>
        <DateField showTime source="created_at" />
        <TextField source="subject_type" />
        <TextField source="description" />
        <MorphField morph="causer" label="Causer" />
      </Datagrid>
    </List>
  );
};

export const ActivityLogTab = (props: Omit<TabProps, 'children'>) => {
  return (
    <Tab {...props}>
      <ConfigurationChangesSection />
    </Tab>
  );
};
