import { jwtDecode as decodeJwt } from 'jwt-decode';
import { addRefreshAuthToAuthProvider, AuthProvider } from 'react-admin';

// Helper Functions
const saveAuthData = (access_token: string, refresh_token: string, expires_in: number) => {
  const permissions = (decodeJwt(access_token) as { permissions: string[] }).permissions;
  localStorage.setItem('permissions', JSON.stringify(permissions));
  localStorage.setItem('token', access_token);
  localStorage.setItem('refresh', refresh_token);
  localStorage.setItem('expires', (new Date().getTime() + expires_in - 5).toString());
};

const clearAuthData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  localStorage.removeItem('expires');
  localStorage.removeItem('permissions');
};

const fetchAuthToken = async (url: string, body: object) => {
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });

  const response = await fetch(request);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }

  return response.json();
};

const refreshAuthToken = async () => {
  const refresh = localStorage.getItem('refresh');
  if (!refresh) {
    throw new Error('No refresh token available');
  }

  const body = {
    grant_type: 'refresh_token',
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
    refresh_token: refresh,
  };

  const data = await fetchAuthToken(process.env.REACT_APP_AUTH_URL as string, body);
  saveAuthData(data.access_token, data.refresh_token, parseInt(data.expires_in));
};

export const checkAuth = async () => {
  const token = localStorage.getItem('token');
  const expires = localStorage.getItem('expires');

  if (token) {
    if (new Date().getTime() > Number(expires)) {
      // Token expired, attempt refresh
      try {
        await refreshAuthToken();
        return Promise.resolve();
      } catch {
        return Promise.reject();
      }
    }
    return Promise.resolve();
  }
  return Promise.reject();
};

// AuthProvider
const baseAuthProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const body = {
      grant_type: process.env.REACT_APP_AUTH_GRANT_TYPE,
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
      scope: process.env.REACT_APP_AUTH_SCOPE,
      username,
      password,
    };

    try {
      const data = await fetchAuthToken(process.env.REACT_APP_AUTH_URL as string, body);
      saveAuthData(data.access_token, data.refresh_token, parseInt(data.expires_in));
    } catch {
      throw new Error('Network error');
    }
  },
  logout: () => {
    clearAuthData();
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      clearAuthData();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: checkAuth,
  getIdentity: async () => {
    const request = new Request(`${process.env.REACT_APP_API_URL}/account`, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.token}`,
      }),
    });

    if (sessionStorage.getItem('profile')) {
      const { name, email } = JSON.parse(sessionStorage.getItem('profile') || '{}');
      return Promise.resolve({ name, email });
    } else {
      try {
        const response = await fetch(request);
        return Promise.resolve(await response.json());
      } catch (error) {
        return Promise.reject(error);
      }
    }
  },
  getPermissions: () => {
    const role = JSON.parse(localStorage.getItem('permissions') || '[]');
    return role ? Promise.resolve(role) : Promise.reject();
  },
  refreshToken: refreshAuthToken,
};

export const authProvider = addRefreshAuthToAuthProvider(baseAuthProvider, checkAuth);
