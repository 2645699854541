import { DialogProps } from '@mui/material';
import { FormDialog } from 'components';
import useDialogContext from 'components/DialogContainer/useDialogContext';
import {
  CreateBase,
  useGetResourceLabel,
  useNotify,
  useRecordContext,
  useResourceContext,
  useTranslate,
} from 'react-admin';

interface CreateDialogProps extends DialogProps {
  title?: string;
  children: React.ReactNode;
}

const CreateDialog = (props: CreateDialogProps) => {
  const { title: customTitle, children } = props;
  const notify = useNotify();
  const record = useRecordContext(props);
  const t = useTranslate();
  const resource = useResourceContext(props);
  const getResourceLabel = useGetResourceLabel();
  const { closeDialog, isCreating } = useDialogContext();

  if (!record) return null;

  const handleSuccess = () => {
    notify('ra.notification.created', {});
    if (closeDialog) closeDialog();
  };
  let title;

  if (customTitle) {
    title = customTitle;
  } else {
    title = t('custom.actions.add', {
      name: getResourceLabel(resource!, 1).replace(/^\w/, (c) => c.toLowerCase()),
      Name: getResourceLabel(resource!, 1),
    });
  }

  return (
    <CreateBase resource={resource} mutationOptions={{ onSuccess: handleSuccess }}>
      {/* TODO: Default values? */}
      <FormDialog title={title} open={!!isCreating} onClose={closeDialog}>
        {children}
      </FormDialog>
    </CreateBase>
  );
};

export default CreateDialog;
