import { InvertColors as FlavourIcon } from '@mui/icons-material';
import { EditGuesser, ResourceProps } from 'react-admin';

import { SubMenu } from 'constants/SubMenu';
import DisplaySurfaceList from './DisplaySurfaceList';
import DisplaySurfaceShow from './DisplaySurfaceShow';

// import { FlavourCreate } from './FlavourCreate';
// import { FlavourEdit } from './FlavourEdit';
// import { FlavourList } from './FlavourList';
// import { FlavourShow } from './FlavourShow';

const DisplaySurfacesResource: ResourceProps = {
  name: 'display_surfaces',
  icon: FlavourIcon,
  list: DisplaySurfaceList,
  show: DisplaySurfaceShow,
  edit: EditGuesser,
  create: EditGuesser,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('manage_flavours'),
    menu: SubMenu.Configuration,
  },
  // list: FlavourList,
  // show: FlavourShow,
  // edit: FlavourEdit,
  // create: FlavourCreate,
  // options: {
  //   isVisible: (hasPermission: Function) => hasPermission('manage_flavours'),
  //   menu: SubMenu.Configuration,
  // },

  recordRepresentation: (record) => {
    return record.name;
  },
};

export default DisplaySurfacesResource;
