import { Box, Card, Divider, Typography } from '@mui/material';
import { FC, ReactNode, createElement } from 'react';
import { Link, To } from 'react-router-dom';

interface Props {
  icon: FC<any>;
  to?: To;
  title?: string;
  subtitle?: ReactNode;
  children?: ReactNode;
}

const CardWithIcon = ({ icon, title, subtitle, to, children }: Props) => (
  <Card
    sx={{
      'minHeight': 52,
      'display': 'flex',
      'flexDirection': 'column',
      'flex': '1',
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
      },
    }}
  >
    {to ? (
      <Link to={to}>
        <BoxContent title={title} subtitle={subtitle} icon={icon} />
      </Link>
    ) : (
      <BoxContent title={title} subtitle={subtitle} icon={icon} />
    )}
    {children && <Divider />}
    {children}
  </Card>
);

const BoxContent = ({ title, subtitle, icon }: Props) => (
  <Box
    sx={{
      'position': 'relative',
      'overflow': 'hidden',
      'padding': '16px',
      'display': 'flex',
      'justifyContent': 'space-between',
      'alignItems': 'center',
      '& .icon': {
        color: 'secondary.main',
      },
      '&:before': {
        position: 'absolute',
        top: '50%',
        left: 0,
        display: 'block',
        content: "''",
        height: '200%',
        aspectRatio: '1',
        transform: 'translate(-30%, -60%)',
        borderRadius: '50%',
        backgroundColor: 'secondary.main',
        opacity: 0.15,
      },
    }}
  >
    <Box width="3em" className="icon">
      {createElement(icon, { fontSize: 'large' })}
    </Box>
    <Box textAlign="right">
      <Typography color="textSecondary">{title}</Typography>
      <Typography variant="h5" component="h2">
        {subtitle || ' '}
      </Typography>
    </Box>
  </Box>
);

export default CardWithIcon;
