import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  Filter,
  ImageField,
  List,
  ListClasses,
  ListToolbar,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from 'react-admin';

export const CollectionSubscriptionsField = (
  props: any = {
    label: 'Subscriptions',
    filter: {},
    source: 'subscription_ids',
    reference: 'subscriptions',
  },
) => {
  const record = useRecordContext();
  const filter = {
    configuration_id: record?.configuration_key,
    ...props.filter,
  };
  return (
    <ReferenceManyField
      target="id"
      source={props.source}
      reference={props.reference}
      filter={filter}
      label={props.label}
      emptyText="-"
      record={record}
    >
      <List
        filter={filter}
        filters={<SubscriptionFilter />}
        empty={<EmptyList />}
        actions={false}
        pagination={false}
        perPage={100}
      >
        <Datagrid size="medium" bulkActionButtons={false} rowSx={subscriptionRowStyle}>
          <ImageField
            label={false}
            source="icon.src"
            sx={{
              '& img': { width: '40px !important', height: '40px !important' },
            }}
            emptyText=""
          />
          <ReferenceField reference="collections" source="app_collection_id" link="show">
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="active" />
          <TextField source="unlock_key" />
          <DateField source="activated_at" />
          <DateField source="valid_from" />
          <DateField source="valid_until" />
          <BooleanField source="auto_renew" />
          <DeleteWithConfirmButton redirect={false} />
        </Datagrid>
      </List>
    </ReferenceManyField>
  );
};

const EmptyList = () => (
  <div className={ListClasses.main}>
    <ListToolbar className={ListClasses.actions} filters={<SubscriptionFilter />} />
    <span> --</span>
  </div>
);

const subscriptionRowStyle = (record: { expired: boolean }) => ({
  backgroundColor: record.expired ? 'rgb(250 250, 250)' : null,
});

const SubscriptionFilter = (props: any) => (
  <Filter {...props}>
    <BooleanInput label="Show expired" source="withExpired" alwaysOn />
  </Filter>
);
