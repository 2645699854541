import { ReferenceField, TextField, useRecordContext } from 'react-admin';

// @ts-ignore

/**
 * Props for the MorphField component.
 *
 * @interface MorphFieldProps
 * @property {string} morph - The morph value to be used in the field.
 * @property {string} [label] - An optional label for the field.
 */
interface MorphFieldProps {
  morph: string;
  label?: string;
}

/**
 * MorphField component is a custom field component that dynamically renders a reference field
 * based on the provided `morph` and `label` props. It uses the `useRecordContext` hook to get
 * the current record and determines the appropriate reference and source fields based on the
 * `morph` value.
 *
 * @param {Object} props - The props object.
 * @param {string} props.morph - The morph type to determine the reference and source fields.
 * @param {string} [props.label] - Optional label for the field.
 *
 * @returns {JSX.Element | null} - Returns a ReferenceField component with the appropriate source
 * and reference fields, or null if no record is found.
 *
 * @typedef {Object} MorphFieldProps
 * @property {string} morph - The morph type to determine the reference and source fields.
 * @property {string} [label] - Optional label for the field.
 */
export const MorphField = ({ morph, label }: MorphFieldProps) => {
  const record = useRecordContext();

  const parseSubject = (morph: string) => {
    if (!morph) return;
    //if subject contains Configuration
    if (morph === 'configuration' || morph.includes('Configuration')) {
      return {
        morphSource: 'id',
        morphReference: 'configurations',
      };
    }
    if (morph === 'unit' || morph.includes('UnitModel')) {
      return {
        morphSource: 'serial',
        morphReference: 'units',
      };
    }
    if (morph === 'user' || morph.includes('User')) {
      return {
        morphSource: 'name',
        morphReference: 'users',
      };
    }

    if (morph === 'subscription' || morph.includes('Subscription')) {
      return {
        morphSource: 'name',
        morphReference: 'subscriptions',
      };
    }
    //return 'configurations'
    //if subject contains Unit
  };
  if (!record) return null;

  const subject = parseSubject(record[morph + '_type']);
  return (
    subject && (
      <ReferenceField source={morph + '_id'} reference={subject!.morphReference}>
        <TextField source={subject!.morphSource} label={label || morph} />
      </ReferenceField>
    )
  );
};
