import { setupEcho } from 'providers/sockets/laravel-echo';
import { useEffect, useState } from 'react';
import { Tab, TabProps, useRecordContext } from 'react-admin';

export const SocketsTab = (props: Omit<TabProps, 'children'>) => {
  const record = useRecordContext();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [messages, setMessages] = useState<string[]>(['hi']);

  useEffect(() => {
    if (!token) return;
    setupEcho(token).then(() => {
      console.log('join/');
      const channel = `tovertafel.${record?.serial}`;
      // @ts-ignore
      window.Echo.private(channel).listen('TestEvent', (e) => {
        console.log('event', e);
        addMessage(JSON.stringify(e));
      });
    });
  }, [record?.serial, token]);

  const addMessage = (message: string) => {
    setMessages((prev) => [...prev, message]);
  };
  const getToken = async () => {
    console.log('get token');
    if (token) return;

    const request = new Request(`${process.env.REACT_APP_AUTH_URL}`, {
      method: 'POST',
      body: JSON.stringify({
        grant_type: 'password',
        client_id: process.env.REACT_APP_REVERB_CLIENT_ID,
        client_secret: process.env.REACT_APP_REVERB_CLIENT_SECRET,
        scope: ['socket'],
        username: record?.serial || '',
        password: record?.hostname || '',
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token, expires_in, refresh_token }) => {
        // @ts-ignore
        setToken(access_token);
      })
      .catch(() => {
        throw new Error('Network error');
      });
  };
  return (
    <Tab {...props}>
      <button onClick={getToken}>Make Connection</button>
      <div
        style={{
          backgroundColor: 'black',
          color: 'white',
          padding: '0.5rem',
        }}
      >
        <ul>
          {messages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </div>
    </Tab>
  );
};

SocketsTab.defaultProps = {
  label: 'resources.units.tabs.socket_inspector',
  value: 'socket_inspector',
  path: 'socket_inspector',
};
