import { Typography } from '@mui/material';
import {
  BooleanField,
  ChipField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  Tab,
  TabProps,
  TextField,
  useTranslate,
} from 'react-admin';

export const ConfigTab = (props: Omit<TabProps, 'children'>) => {
  const translate = useTranslate();
  return (
    <Tab {...props}>
      <Typography variant="h6">{translate('resources.units.section.localization')}</Typography>
      <ReferenceField source="locale_id" reference="locales" emptyText="-" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField source="language_ids" reference="locales">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>

      <Typography variant="h6">{translate('resources.units.section.preferences')}</Typography>
      <ReferenceField source="designation_id" reference="designations" emptyText="-" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="update_channel_id" reference="update_channels" emptyText="-">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="show_uptime" emptyText="-" />

      <Typography variant="h6">{translate('resources.units.section.access_control')}</Typography>
      <BooleanField source="studio_access" />

      <Typography variant="h6">{translate('resources.units.section.legal')}</Typography>
      <BooleanField source="require_data_consent" />
    </Tab>
  );
};
