import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';

const MultileConfigOnUnit = () => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    dataProvider.get('dashboard/unitsWithMultipleConfigurations').then((res: any) => {
      setCount(res.total);
    });
  }, [dataProvider]);

  return (
    <CardWithIcon
      icon={CrisisAlertIcon}
      title={translate('resources.dashboard.unitsWithMultipleConfigurations', {
        _: 'Units with multiple configurations',
        smart_count: 1,
      })}
      subtitle={count}
    />
  );
};

export default MultileConfigOnUnit;
