import { ImageField } from 'react-admin';

/**
 * Props for the Icon component.
 *
 * @typedef {Object} IconProps
 * @property {string} source - The source URL or path for the icon image.
 * @property {number} [size] - Optional size of the icon. Defaults to a standard size if not provided.
 * @property {boolean} [sortable] - Optional flag indicating if the icon is sortable. Defaults to false if not provided.
 */
type IconProps = {
  source: string;
  size?: number;
  sortable?: boolean;
};

/**
 * IconField component renders an ImageField with specific size constraints.
 *
 * @param {IconProps} props - The properties for the IconField component.
 * @param {string} props.source - The source URL for the image.
 * @param {number} [props.size=60] - The size of the image in pixels. Defaults to 60 if not provided.
 * @param {boolean} [props.sortable] - Indicates if the field is sortable.
 *
 * @returns {JSX.Element} The rendered ImageField component with the specified size and properties.
 */
const IconField = (props: IconProps) => {
  const size = props.size ?? 60;
  return (
    <ImageField
      label="custom.labels.icon"
      source={props.source}
      sx={{
        '& img': {
          minWidth: size,
          maxWidth: size,
          minHeight: size,
          maxHeight: size,
          objectFit: 'contain',
        },
      }}
      sortable={props.sortable}
    />
  );
};
export default IconField;
