import { Box, Typography } from '@mui/material';
import { Button, Login, LoginForm } from 'react-admin';
import { Link } from 'react-router-dom';

const LoginPage = () => {
  return (
    <Login>
      <LoginForm />
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button component={Link} to="/forgotPassword" variant="text" sx={{ textTransform: 'none' }}>
          <Typography variant="body2">Forgot Password?</Typography>
        </Button>
      </Box>
    </Login>
  );
};

export default LoginPage;
