import { SvgIcon } from '@mui/material';

const SFIcon = (props: any) => (
  <SvgIcon {...props}>
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="164"
      viewBox="0 0 164 116"
      enableBackground="new 0 0 180 180"
      height="116"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#01a1e0"
        opacity="1"
        stroke="none"
        d="m 159.93526,39.161479 c 8.20153,24.930807 -8.98184,47.768153 -34.71209,46.856222 -1.90672,-0.06757 -4.48277,0.408278 -5.68367,1.655759 -10.06563,10.455719 -16.97562,12.43612 -31.684425,8.359525 C 83.080278,104.90043 76.053654,111.02266 65.656525,112.51399 53.401387,114.2718 42.444999,108.86292 36.034198,98.216796 35.118796,96.69662 32.899858,94.76368 31.499171,94.922115 19.844662,96.240416 7.590637,88.417881 3.618118,77.031221 -0.5034203,65.217419 3.244126,54.552569 14.911773,45.018311 10.813599,26.443345 15.00842,14.133328 28.037089,6.5010468 41.389774,-1.3210473 54.861425,1.0203912 68.889944,13.772845 74.666128,8.8940463 81.115642,5.4992912 89.049553,5.6369697 c 9.177797,0.1592644 16.627637,3.735862 22.258997,10.8978423 1.59763,2.031886 2.74144,3.198318 5.74345,2.396812 19.02485,-5.079424 34.29131,2.103612 42.88326,20.229855 z"
        id="path1"
      />
    </svg>
  </SvgIcon>
);

export default SFIcon;
