import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  ReferenceArrayField,
  ShowBase,
  ShowProps,
  SimpleShowLayout,
  TextField,
  Title,
  useShowContext,
  useTranslate,
} from 'react-admin';

export const PartnerShow: FC<ShowProps> = () => {
  return (
    <ShowBase>
      <PartnerShowView />
    </ShowBase>
  );
};

const PartnerShowView = (props: any) => {
  const translate = useTranslate();
  const { title } = props;
  const { resource, defaultTitle } = useShowContext();

  return (
    <>
      <Title title={title} defaultTitle={defaultTitle} preferenceKey={`${resource}.show.title`} />
      <Stack>
        <Grid container flexDirection="row">
          <Grid container spacing={4} justifyContent="flex-end" sx={{ mt: 4 }}>
            <EditButton />
            <DeleteButton />
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ mb: 2 }}>
                {translate('resources.partners.section.general')}
              </Typography>
              <Paper>
                <SimpleShowLayout sx={{ p: 2 }}>
                  <TextField source="name" />
                  <TextField source="sales_force_id" emptyText="-" />
                  <TextField source="client_number" emptyText="-" />
                  <TextField source="service_person" emptyText="-" />
                  <TextField source="sales_email" emptyText="-" />
                  <TextField source="service_email" emptyText="-" />
                  <TextField source="service_phone_number" emptyText="-" />
                </SimpleShowLayout>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ mb: 2 }}>
                {translate('resources.partners.section.partner_groups')}
              </Typography>
              <ReferenceArrayField reference="partner_groups" source="partner_group_ids">
                <Paper>
                  <Datagrid
                    bulkActionButtons={false}
                    empty={
                      <Box sx={{ p: 2 }}>
                        <Typography variant="body2">{translate('resources.partner_groups.empty')}</Typography>
                      </Box>
                    }
                  >
                    <TextField source="name" />
                  </Datagrid>
                </Paper>
              </ReferenceArrayField>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
