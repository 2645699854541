import { Grid, Typography } from '@mui/material';
import Protect from 'components/Protect';
import {
  AutocompleteInput,
  FormTab,
  FormTabProps,
  ReferenceInput,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { useTabbedFormTabHandler } from '../useTabbedFormTabHandler';

const FilteredPartnerGroups = () => {
  const realtimePartnerId = useWatch({ name: 'partner_id' });

  return (
    <ReferenceInput
      source="partner_group_id"
      reference="partner_groups"
      filter={{ partner_id: realtimePartnerId }}
      sx={{ visibility: realtimePartnerId ? 'visible' : 'hidden' }}
    >
      <Protect permissions="manage_location_contact">
        <SelectInput optionText="name" optionValue="id" fullWidth />
      </Protect>
    </ReferenceInput>
  );
};

export const UnitEditInfo = (props: FormTabProps) => {
  const translate = useTranslate();
  const hardware_id = useWatch({ name: 'hardware_id' });
  useTabbedFormTabHandler(props);
  return (
    <FormTab {...props}>
      <Protect hasAny={[/(serial|hostname) write$/, 'manage_location_contact']} permissions="manage_units">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <Typography variant="h6">{translate('resources.units.section.general')}</Typography>
            <Grid item>
              <Protect permissions="manage_units">
                <TextInput source="serial" fullWidth />
              </Protect>
            </Grid>
            <Grid item>
              <Protect permissions="manage_units">
                <TextInput source="configuration_key" fullWidth />
              </Protect>
            </Grid>
            <Grid item>
              <ReferenceInput source="hardware_id" reference="hardware">
                <Protect permissions="manage_units">
                  <AutocompleteInput disabled={hardware_id === 3} />
                </Protect>
              </ReferenceInput>
            </Grid>
            <Grid item>
              <ReferenceInput source="hardware_revision_id" reference="hardware_revisions" filter={{ hardware_id }}>
                <Protect permissions="manage_units">
                  <AutocompleteInput disabled={hardware_id === 3} />
                </Protect>
              </ReferenceInput>
            </Grid>
            <Grid item>
              <Protect permissions="manage_units">
                <TextInput
                  source="hostname"
                  fullWidth
                  disabled={hardware_id === 3}
                  helperText={hardware_id === 3 ? 'resources.units.helpers.hostname' : undefined}
                />
              </Protect>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} container direction="column">
            <Typography variant="h6">{translate('resources.units.section.partner')}</Typography>
            <Grid item>
              <ReferenceInput source="partner_id" reference="partners">
                <Protect permissions="manage_location_contact">
                  <SelectInput optionText="name" optionValue="id" fullWidth />
                </Protect>
              </ReferenceInput>
            </Grid>
            <Grid item>
              <FilteredPartnerGroups />
            </Grid>
          </Grid>
        </Grid>
      </Protect>
    </FormTab>
  );
};
