import { FC } from 'react';
import {
  ArrayField,
  BooleanField,
  CloneButton,
  Datagrid,
  Labeled,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { FormattedDateField } from 'components/FormattedDateField';

const softwareReleaseFilters = [
  <SearchInput key="search" source="q" alwaysOn />,
  <ReferenceInput key="hardware" source="hardware_id" reference="hardware" alwaysOn>
    <SelectInput source="name" />
  </ReferenceInput>,
];

const CloneRelease = () => {
  const record = useRecordContext();
  const clearName = ({ name, ...rest }: any) => rest;

  return record ? <CloneButton record={clearName(record)} /> : null;
};

const SoftwareComponentExpand = () => {
  const translate = useTranslate();

  return (
    <Labeled
      label={translate('resources.software_components.name', {
        smart_count: 2,
      })}
      fullWidth
    >
      <ArrayField source="software_components">
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <ReferenceField source="software_version_id" reference="software_versions">
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      </ArrayField>
    </Labeled>
  );
};

export const SoftwareReleaseList: FC = () => {
  return (
    <List filters={softwareReleaseFilters} sort={{ field: 'created_at', order: 'DESC' }}>
      <Datagrid rowClick="show" bulkActionButtons={false} expand={<SoftwareComponentExpand />} expandSingle>
        <TextField source="name" />
        <ReferenceField source="hardware_id" reference="hardware" emptyText="-">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="hardware_revision_id" reference="hardware_revisions" emptyText="-">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="custom" />
        <FormattedDateField source="created_at" showTime />
        <CloneRelease />
      </Datagrid>
    </List>
  );
};
