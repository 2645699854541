import { FC } from 'react';
import { ChipField, ChipFieldProps, useRecordContext } from 'react-admin';

const FlavourChipField: FC<ChipFieldProps> = ({ source = 'name', ...props }) => {
  const record = useRecordContext();
  if (!record || !record?.name) return null;

  return <ChipField source={source} {...props} sx={{ background: record.color || '#eee' }} />;
};

export default FlavourChipField;
