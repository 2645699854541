import { Grid2 as Grid } from '@mui/material';
import { AutocompleteArrayInput, Edit, ReferenceArrayInput, SimpleForm, TextInput } from 'react-admin';

const UserEdit = () => (
  <Edit>
    <SimpleForm>
      <Grid container direction="column" component="div" sx={{ xs: 12, lg: 6, xl: 6 }}>
        <TextInput source="name" />
        <TextInput source="email" type="email" />
        <ReferenceArrayInput source="role_ids" reference="roles">
          <AutocompleteArrayInput optionText="short_description" optionValue="id" />
        </ReferenceArrayInput>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default UserEdit;
