// CollapsibleEditableArray.tsx
import { ExpandMore } from '@mui/icons-material';
import { Card, CardActions, CardContent, Collapse, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ArrayInput, SimpleFormIterator, TextInput, useRecordContext } from 'react-admin';

interface CollapsibleEditableArrayProps {
  data?: Record<string, any>;
  source?: string;
}

const CollapsibleEditableArray: React.FC<CollapsibleEditableArrayProps> = ({ data, source }) => {
  const record = useRecordContext();
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});
  const { id, ...localData } = data || record || {};

  const handleToggle = (key: string) => {
    setOpenSections((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const renderArray = (data: Record<string, any>, parentKey = '') => {
    if (!data || typeof data !== 'object') {
      return null;
    }

    return Object.keys(data).map((key) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      const value = data[key];
      const isObject = typeof value === 'object' && value !== null;
      const isArray = Array.isArray(value);
      if (!isObject && !isArray) {
        return <TextInput fullWidth source={currentKey} placeholder="Empty" />;
      }

      return (
        <React.Fragment key={currentKey}>
          <CardActions disableSpacing>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {key}
            </Typography>
            <IconButton
              onClick={() => handleToggle(currentKey)}
              aria-expanded={openSections[currentKey]}
              aria-label="show more"
            >
              <ExpandMore />
            </IconButton>
          </CardActions>
          <Collapse in={openSections[currentKey]} timeout="auto" unmountOnExit>
            <CardContent>
              {isObject && !isArray ? (
                renderArray(value, currentKey)
              ) : (
                <ArrayInput source={currentKey} defaultValue={value}>
                  <SimpleFormIterator inline>
                    {!value[0] || (!Array.isArray(value[0]) && !(typeof value === 'object')) ? (
                      <TextInput source="value" />
                    ) : (
                      value[0] && Object.keys(value[0]).map((k) => <TextInput source={k} />)
                    )}
                  </SimpleFormIterator>
                </ArrayInput>
              )}
            </CardContent>
          </Collapse>
        </React.Fragment>
      );
    });
  };

  return <Card sx={{ mb: 2, width: '100%' }}>{renderArray(localData)}</Card>;
};

export default CollapsibleEditableArray;
