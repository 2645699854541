import { useRecordContext } from 'react-admin';
import { get as getValue } from 'lodash';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const OnlineStatusField = ({ source }: any) => {
  const record = useRecordContext();
  const value = getValue(record, source, '');
  let htmlColor = 'grey';
  switch (value) {
    case 'offline':
      htmlColor = 'red';
      break;
    case 'online':
      htmlColor = 'orange';
      break;
    case 'realtime':
      htmlColor = 'green';
      break;
    default:
  }
  return (
    <span title={value}>
      <PowerSettingsNewIcon htmlColor={htmlColor} />
    </span>
  );
};
export default OnlineStatusField;
