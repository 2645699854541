import { checkAuth } from 'providers/auth';
import { stringify } from 'qs';
import { UpdateManyResult } from 'ra-core';
import { addRefreshAuthToDataProvider, DataProvider, HttpError, OnError, OnSuccess } from 'react-admin';
import { AccountPayload } from 'types/Account';
import { createClient, getIds, getQueryFromParams, setInterceptors } from './helpers';
import { defaultSettings } from './settings';

type DataProviderFactory<ResourceType extends string = string> = (
  apiUrl: string,
  customSettings?: Record<any, unknown>,
  tokenName?: string,
) => DataProvider<ResourceType>;

// Helper to parse form data
const parseIntoFormData = (data: any, method?: string) => {
  if (data.icon?.rawFile instanceof File) {
    let formData = new FormData();

    for (let property in data) {
      if (property === 'icon') {
        formData.append('icon', data.icon.rawFile, data.icon.title);
      } else if (typeof data[property] === 'object') {
        for (let property_id in data[property]) {
          formData.append(property + '[]', data[property][property_id]);
        }
      } else {
        formData.append(property, data[property]);
      }
    }

    if (method) {
      formData.append('_method', method);
      method = 'post';
    }

    let contentType = 'multipart/form-data';

    data = formData;
    return [data, contentType, method];
  } else {
    delete data.icon;
    return [data];
  }
};

// DataProvider factory
const dataProviderFactory: DataProviderFactory = (apiURL, customSettings = {}, tokenName = 'token') => {
  const settings = { ...defaultSettings, ...customSettings };
  const options = { headers: settings.headers };
  const client = createClient(apiURL, settings);

  setInterceptors(client, tokenName);

  return {
    getFilterList: async (resource: string) => {
      const url = `${apiURL}/${resource}/filters`;
      const res = await client({ url, ...options });

      return {
        data: res.data.data,
        total: res.data.total || res.data.count || 0,
      };
    },
    getList: async (resource, params) => {
      const query = getQueryFromParams({ ...params, ...customSettings });
      const url = `${apiURL}/${resource}?${stringify(query)}`;
      const res = await client({ url, ...options });

      return {
        data: res.data.data,
        total: res.data.total || 0,
      };
    },
    getOne: async (resource, params) => {
      const url = `${apiURL}/${resource}/${params.id}`;
      const res = await client.get(url);

      return { data: res.data };
    },
    getMany: async (resource, params) => {
      const query = getIds(params);
      const url = `${apiURL}/${resource}?${query}`;
      const res = await client({ url, ...options });

      return {
        data: res.data.data,
        total: res.data.total,
      };
    },
    getManyReference: async (resource, params) => {
      const query = getQueryFromParams({ ...params, ...customSettings });
      const url = `${apiURL}/${resource}?${stringify(query)}`;
      const res = await client({ url, ...options });

      return {
        data: res.data.data,
        total: res.data.total,
      };
    },
    create: async (resource, params) => {
      const url = `${apiURL}/${resource}`;
      let settings = {};
      let data = undefined;
      let contentType = undefined;

      for (let property in params.data) {
        if (params.data[property] === null || params.data[property] === undefined) {
          delete params.data[property];
        }
      }
      if (['apps', 'collections'].indexOf(resource) > -1) {
        [data, contentType] = parseIntoFormData(params.data);
      }
      if (!data) {
        data = { ...params.data };
      }

      if (contentType) {
        settings = {
          ...client.prototype.headers,
          headers: {
            'Content-Type': contentType,
          },
        };
      }

      try {
        const res = await client.post(url, data, settings);

        return {
          data: {
            id: res.data.id,
            ...res.data.attributes,
          },
        };
      } catch (error: any) {
        validationErrorHandling(error);
        throw error;
      }
    },
    update: async (resource, params) => {
      const url = `${apiURL}/${resource}/${params.id}`;
      let data = {
        ...params.data,
      };
      delete data.id;

      let settings = {};
      let method = undefined;
      let contentType = undefined;

      if (['apps', 'collections'].indexOf(resource) > -1) {
        [data, contentType, method] = parseIntoFormData(data, 'put');
      }
      if (contentType) {
        settings = {
          ...client.prototype.headers,
          headers: {
            'Content-Type': contentType,
          },
        };
      }
      if (!method) method = 'put';

      try {
        const res = method === 'put' ? await client.put(url, data, settings) : await client.post(url, data, settings);

        return {
          data: res.data,
        };
      } catch (error: any) {
        validationErrorHandling(error);
        throw error;
      }
    },
    updateMany: (_resource, _params) => {
      return Promise.reject<UpdateManyResult>();
    },
    delete: async (resource, params) => {
      const url = `${apiURL}/${resource}/${params.id}`;
      const res = await client.delete(url);

      return { data: res.data };
    },
    deleteMany: async (resource, params) => {
      const query = getIds(params, settings.arrayFormat);
      const url = `${apiURL}/${resource}/${query}`;
      const res = await client.delete(url);

      return { data: res.data };
    },
    get: async (endpoint: string) => {
      const url = `${apiURL}/${endpoint}`;
      const res = await client.get(url);

      return res.data;
    },
    post: async (endpoint: string, data: any) => {
      const url = `${apiURL}/${endpoint}`;
      const res = await client.post(url, data);
      return res.data;
    },
    download: async (endpoint: string) => {
      const url = `${apiURL}/${endpoint}`;
      const res = await client.get(url, { responseType: 'blob' });

      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        const contentDispositionHeader = res.headers['content-disposition'] as string;

        link.href = url;

        let filenames = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);

        if (filenames && filenames.length > 0) {
          let filename = filenames[1];

          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      }
      return res.data;
    },
    getUserProfile: async () => {
      const storedProfile = sessionStorage.getItem('profile');

      if (storedProfile) {
        return Promise.resolve({
          data: JSON.parse(storedProfile),
        });
      }

      // No stored profile yet, get one for auth user
      const url = `${apiURL}/account`;
      const res = await client.get(url);

      if (res) {
        sessionStorage.setItem('profile', JSON.stringify(res.data));
      }

      return { data: res.data };
    },
    updateUserProfile: async (resource: AccountPayload, params: { onSuccess: OnSuccess; onFailure: OnError }) => {
      const url = `${apiURL}/account`;
      const res = await client.patch(url, resource);

      if (res) {
        sessionStorage.setItem('profile', JSON.stringify({ ...res.data }));
      }

      return { data: res.data };
    },
    getDiagnostics: async (resource: string, { serial, type }: { serial: string; type?: string }) => {
      const url = `${apiURL}/${resource}/${serial}/reports` + (type ? `/${type}` : '');
      const res = await client({ url, ...options });

      return {
        data: res.data.data,
        total: res.data.total ?? 0,
      };
    },
    getCurrentlyOnlineCount: async () => {
      const url = `${apiURL}/units/currently_online_count`;
      const res = await client({ url, ...options });

      return {
        data: res.data.data,
      };
    },
  };
};

// Export wrapped dataProvider
export const dataProvider = addRefreshAuthToDataProvider(
  dataProviderFactory(process.env.REACT_APP_API_URL || ''),
  checkAuth,
);

/**
 * Handles validation errors returned from the server.
 */
function validationErrorHandling(error: any) {
  if (error.response && error.response.status === 422) {
    const errors = error.response.data.errors;
    const errorMessages: Record<string, { message: string; args: any }> = {};
    for (const field in errors) {
      errors[field] = errors[field].map((error: string) => {
        const parts = error.split(':');
        const message = parts.shift();
        const args = (parts.shift() || '').split(',');
        return { message, args };
      });
      errorMessages[field] = errors[field].shift();
    }
    throw new HttpError('validation.error', error.response.status, {
      errors: errorMessages,
    });
  }
}
