import { FC } from 'react';
import {
  BooleanField,
  ChipField,
  NumberField,
  ReferenceArrayField,
  Show,
  ShowProps,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from 'react-admin';

import { Divider } from '@mui/material';
import IconField from '../../components/Fields/IconField';
import FlavourChipField from '../flavours/FlavourChipField';
import HardwareIcon from '../hardware/icons/HardwareIcon';

const AppShow: FC<ShowProps> = () => (
  <Show>
    <SimpleShowLayout>
      <IconField source="icon.src" size={128} />
      <TextField source="name" />
      <TextField source="translated_name" />
      <TextField source="product_id" emptyText="-" />
      <Divider />
      <NumberField source="cognitive" />
      <BooleanField source="lab_enabled" />
      <ReferenceArrayField source="flavour_ids" reference="flavours" emptyText="-">
        <SingleFieldList linkType="show">
          <FlavourChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="hardware_ids" reference="hardware" emptyText="-">
        <SingleFieldList linkType="show">
          <HardwareIcon source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="display_surface_ids" reference="display_surfaces" emptyText="-">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="designation_ids" reference="designations" emptyText="-">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="locale_ids" reference="locales" emptyText="-">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="app_collection_ids" reference="collections" emptyText="-">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="playlist_ids" reference="playlists" emptyText="-">
        <SingleFieldList linkType="show">
          <ChipField source="key" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);

export default AppShow;
