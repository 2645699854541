import { Alert, Box, Button, Card, CardActions, CircularProgress, TextField, Typography } from '@mui/material';
import { useNotify, useTranslate } from 'ra-core';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const ForgotPasswordPage = () => {
  const [sent, setSent] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      setFailed(false);
      setSent(false);
      //throw new Error('Not implemented');
      // Replace this with your actual password reset API call
      await fetch(`${process.env.REACT_APP_API_URL}/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      notify('custom.auth.forgot_password.success');
      setEmail('');
      setSent(true);
      setFailed(false);
    } catch (error) {
      notify('custom.auth.forgot_password.failed', { type: 'error' });
      setFailed(true);
    }

    setLoading(false);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh" bgcolor="background.default">
      <Card sx={{ minWidth: 300, padding: '1em' }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ padding: '1em' }}>
          <Box sx={{ marginBottom: '1em' }}>
            <Typography variant="h4" gutterBottom>
              {translate('custom.auth.forgot_password.title')}
            </Typography>
            {!sent && <Typography variant="body1">{translate('custom.auth.forgot_password.subtitle')}</Typography>}
          </Box>
          {sent && (
            <Box sx={{ marginBottom: '1em' }}>
              <Alert severity="success">
                <Typography variant="body1" color="success">
                  {translate('custom.auth.forgot_password.success')}
                </Typography>
              </Alert>
            </Box>
          )}
          {failed && (
            <Box sx={{ marginBottom: '1em' }}>
              <Alert severity="error">
                <Typography variant="body1" color="error">
                  {translate('custom.auth.forgot_password.failed')}
                </Typography>
              </Alert>
            </Box>
          )}
          {!sent && (
            <>
              <Box sx={{ marginBottom: '1em' }}>
                <TextField
                  autoFocus
                  fullWidth
                  label={translate('custom.auth.forgot_password.email')}
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                />
              </Box>
              <CardActions sx={{ padding: 0 }}>
                <Button variant="contained" type="submit" color="primary" disabled={loading} fullWidth>
                  {loading ? (
                    <CircularProgress size={25} thickness={2} />
                  ) : (
                    translate('custom.auth.forgot_password.submit')
                  )}
                </Button>
              </CardActions>
              <Box mt={2}>
                <Link to="/login" style={{ textDecoration: 'none' }}>
                  <Button fullWidth>{translate('custom.auth.forgot_password.back_to_login')}</Button>
                </Link>
              </Box>
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default ForgotPasswordPage;
