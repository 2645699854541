import { Show, SimpleShowLayout, TextField } from 'react-admin';

const DisplaySurfaceShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);

export default DisplaySurfaceShow;
