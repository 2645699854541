import {
  BooleanField,
  Datagrid,
  DateField,
  DeleteButton,
  ImageField,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from 'react-admin';

export const CollectionTrialsField = (
  props: any = {
    label: 'Trial Collections',
    filter: {},
    source: 'app_collection_trial_ids',
    reference: 'app_collection_trials',
  },
) => {
  const record = useRecordContext();
  const filter = {
    configuration_id: record?.configuration_key,
    ...props.filter,
  };
  return (
    <ReferenceManyField
      target="id"
      source={props.source}
      reference={props.reference}
      filter={filter}
      label={props.label}
      emptyText="-"
      record={record}
    >
      <Datagrid size="medium" bulkActionButtons={false} empty={<>-</>}>
        <ImageField
          label={false}
          source="icon.src"
          sx={{
            '& img': { width: '40px !important', height: '40px !important' },
          }}
          emptyText=""
        />
        <ReferenceField reference="collections" source="app_collection_id" link="show">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="active" />
        <DateField source="valid_from" />
        <DateField source="valid_until" />
        <DeleteButton />
      </Datagrid>
    </ReferenceManyField>
  );
};
