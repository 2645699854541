import { Box, Card, CardContent, Typography } from '@mui/material';
import { Button, Form, TextInput, minLength, required, useNotify, useRedirect } from 'react-admin';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const ResetPasswordPage = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const email = searchParams.get('email');

  const handleSubmit = async (values: any) => {
    try {
      // Call your API endpoint to reset password
      const response = await fetch(`${process.env.REACT_APP_API_URL}/../../password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          password: values.password,
          password_confirmation: values.confirmPassword,
          email,
          token: params.token,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'custom.auth.reset_password.failed');
      }

      notify('custom.auth.reset_password.success', { type: 'success' });
      redirect('/login');
    } catch (error) {
      console.log(
        error instanceof Error ? 'custom.auth.reset_password.' + error.message : 'custom.auth.reset_password.failed',
      );
      notify(
        error instanceof Error ? 'custom.auth.reset_password.' + error.message : 'custom.auth.reset_password.failed',
        { type: 'error' },
      );
    }
  };
  return (
    <Card
      sx={{
        minWidth: 300,
        maxWidth: 500,
        margin: 'auto',
        marginTop: 6,
      }}
    >
      <CardContent>
        <Typography variant="h5" gutterBottom align="center">
          Reset Password
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 3 }}>
          Please enter your new password
        </Typography>

        <ResetPasswordForm onSubmit={handleSubmit} />
      </CardContent>
    </Card>
  );
};

const ResetPasswordForm = ({ onSubmit }: { onSubmit: SubmitHandler<FieldValues> }) => {
  // Form validation rules
  const validatePassword = [required(), minLength(8)];
  const validateConfirmPassword = [
    required(),
    (value: string, allValues: any) => {
      if (value !== allValues.password) {
        return 'Passwords do not match';
      }
      return undefined;
    },
  ];

  return (
    <Form onSubmit={onSubmit}>
      <Box maxWidth={400} marginTop={2}>
        <TextInput source="password" type="password" label="New Password" fullWidth validate={validatePassword} />
        <TextInput
          source="confirmPassword"
          type="password"
          label="Confirm Password"
          fullWidth
          validate={validateConfirmPassword}
        />
        <Box marginTop={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth label="Reset password" />
        </Box>
      </Box>
    </Form>
  );
};

export default ResetPasswordPage;
