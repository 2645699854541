import { Card, CardContent, CardHeader, Theme, useMediaQuery } from '@mui/material';
import ResponseStatusField from 'components/ResponseStatusField';
import TimeAgoField from 'components/TimeAgoField';
import TruncatedTextField from 'components/TruncatedTextField';
import { usePermission } from 'providers/auth/permissionProvider';
import { CSSProperties } from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import LastOnlineUnits from './LastOnlineUnits';
import MissingUnits from './MissingUnits';
import CurrentlyConnectedUnits from './CurrentlyConnectedUnits';
import MultileConfigOnUnit from './MultipleConfigOnUnit';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

// @TODO stays like this for now as backbone, we can add items later - Ferenc
const Dashboard = () => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { hasPermission } = usePermission();
  if (!hasPermission('view_dashboard')) return null;

  if (isXSmall) {
    return <XSMALLView />;
  } else if (isSmall) {
    return <SMALLView />;
  } else {
    return <MEDIUMView />;
  }
};
const TelescopeCard = () => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Latest errors" />
      <CardContent>
        <List resource="telescope/requests">
          <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="content.method" label="Method" sortable={false} />
            <TruncatedTextField source="content.uri" maxLength={40} label="Path" />
            <ResponseStatusField source="status" label="Status" />
            {!isXSmall && <TextField source="content.duration" label="Duration" />}
            <TimeAgoField source="created_at" label="Happened" />
          </Datagrid>
        </List>
      </CardContent>
    </Card>
  );
};

const XSMALLView = () => {
  return (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <CurrentlyConnectedUnits />
        <VerticalSpacer />
        <LastOnlineUnits value={24} />
        <VerticalSpacer />
        <MultileConfigOnUnit />
        <VerticalSpacer />
        <MissingUnits />
        <VerticalSpacer />
        <TelescopeCard />
      </div>
    </div>
  );
};

const SMALLView = () => {
  return (
    <div style={styles.flexColumn as CSSProperties}>
      <div style={styles.singleCol}>{/* <Welcome /> */}</div>
      <div style={styles.flex}>
        <CurrentlyConnectedUnits />
        <Spacer />
        <LastOnlineUnits value={24} />
        <Spacer />
        <MultileConfigOnUnit />
        <Spacer />
        <MissingUnits />
      </div>
      <div style={styles.singleCol}>
        <TelescopeCard />
      </div>
    </div>
  );
};

const MEDIUMView = () => {
  return (
    <>
      <div style={styles.flex}>
        <CurrentlyConnectedUnits />
        <Spacer />
        <LastOnlineUnits value={24} />
        <Spacer />
        <MultileConfigOnUnit />
        <Spacer />
        <MissingUnits />
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <Spacer />
            <Spacer />
            <Spacer />
          </div>
          <div style={styles.singleCol}>{/* <OrderChart orders={recentOrders} /> */}</div>
          <div style={styles.singleCol}>{/* <PendingOrders orders={pendingOrders} /> */}</div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.flex} />
        </div>
      </div>
      <VerticalSpacer />
      <TelescopeCard />
    </>
  );
};

export default Dashboard;
