import dutchMessages from 'components/Languages/ra-language-dutch';
import _ from 'lodash';
import { StringMap, TranslationMessages } from 'react-admin';

const extendedDutchMessages = {
  ra: {
    action: {
      update_application: 'Update applicatie',
    },
    message: {
      auth_error: 'Er is een fout opgetreden bij het valideren van het authenticatietoken.',
      clear_array_input: 'Weet u zeker dat u de hele lijst wilt wissen?',
    },
    notification: {
      application_update_available:
        'Er is een nieuwe versie van de applicatie beschikbaar. Klik hier om de pagina te herladen.',
    },
    navigation: {
      clear_filters: 'Filters wissen',
      no_more_results: 'Geen resultaten meer',
      no_filtered_results: 'Geen gefilterde resultaten',
    },
  },
};
//@ts-ignore
export const nl: TranslationMessages = {
  ..._.merge<StringMap, StringMap>(dutchMessages, extendedDutchMessages),

  custom: {
    auth: {
      login: {
        title: 'Inloggen',
        subtitle: 'Voer uw e-mailadres en wachtwoord in',
        email: 'E-mail',
        password: 'XXXXXXXX',
        submit: 'Inloggen',
        forgot_password: 'Wachtwoord vergeten?',
        error: 'Ongeldige inloggegevens',
      },
      forgot_password: {
        title: 'Wachtwoord vergeten',
        subtitle: 'Voer uw e-mailadres in om uw wachtwoord opnieuw in te stellen',
        email: 'E-mail',
        submit: 'Wachtwoord opnieuw instellen',
        back_to_login: 'Terug naar inloggen',
        error: 'Fout bij het verzenden van de instructies voor het opnieuw instellen van het wachtwoord',
        success: 'Instructies voor het opnieuw instellen van het wachtwoord zijn verzonden',
        failed: 'Fout bij het verzenden van de instructies voor het opnieuw instellen van het wachtwoord',
      },
      reset_password: {
        title: 'Wachtwoord opnieuw instellen',
        subtitle: 'Voer uw nieuwe wachtwoord in',
        password: 'XXXXXXXX',
        confirm_password: 'Bevestig wachtwoord',
        submit: 'Wachtwoord opnieuw instellen',
        back_to_login: 'Terug naar inloggen',
        error: 'Fout bij het opnieuw instellen van het wachtwoord',
        success: 'Wachtwoord opnieuw instellen gelukt',
        failed: 'Fout bij het opnieuw instellen van het wachtwoord',
        passwords: {
          token: 'Ongeldig token',
          email: 'Email',
        },
        validation: {
          confirmed: 'Wachtwoorden komen niet overeen',
        },
      },
      verify_email: {
        title: 'Verify email',
        subtitle: 'Please enter the code sent to your email',
        code: 'Code',
        submit: 'Verify email',
        back_to_login: 'Back to login',
        error: 'Invalid code',
      },
    },
    actions: {
      add: '%{Name} toevoegen',
      copy: 'Kopieer naar klembord',
      download: 'Download',
      edit: '%{Name} bijwerken',
      request: 'Aanvragen',
      show: 'Toon %{name}',
    },
    labels: {
      actions: 'Acties',
      icon: 'Icoon',
      options: 'Opties',
      used_by: 'Gebruikt door',
    },
    notification: {
      error: {
        not_found: '%{item} niet gevonden',
      },
      success: '%{action} geslaagd',
    },
  },

  message: {
    add_title: '%{resource} aan %{target} toevoegen.',
  },
  validation: {
    error: 'Validatiefout',
    exists: 'Item bestaat niet',
    required: 'Vereist',
    unique: 'Item bestaat al',
  },
  resources: {
    app_collection_defaults: {
      fields: {
        app_collection_id: 'Collectie',
        created_at: 'Aanmaakdatum',
      },
    },
    app_collection_subscriptions: {
      name: 'Abonnement |||| Abonnementen',
      fields: {
        app_collection_id: 'Collectie',
        length: 'Duur',
        my_tover_access: 'Toegang tot My Tover',
        start_date: 'Startdatum',
        start_now: 'Nu starten',
        invoice_number: 'Factuurnummer',
      },
    },

    apps: {
      fields: {
        designation: {
          id: 'Toewijzing',
        },
        designation_ids: 'Toewijzing(en)',
        enabled: 'Ingeschakeld',
        flavour_id: 'Doelgroep',
        name: 'Gamecode',
        product_id: 'Product ID',
        translated_name: 'Naam',
      },
    },
    collections: {
      name: 'Collectie |||| Collecties',
      fields: {
        available_as_subscription: 'Verkrijgbaar als abonnement?',
        designation_ids: 'Toewijzing(en)',
        flavour_id: 'Doelgroep',
        name: 'Naam',
      },
      tabs: {
        general: 'General',
        apps: 'Apps',
      },
    },
    countries: {
      name: 'Land |||| Landen',
      fields: {
        active: 'Toon alleen actief',
        name: 'Naam',
      },
      tabs: {
        general: 'Algemeen',
        regions: "Regio's",
      },
    },
    designations: {
      name: 'Toewijzing |||| Toewijzingen',
      fields: {
        app_collection_ids: 'Collecties',
        default: 'Standaard',
        designation_ids: 'Toewijzingen',
        name: 'Naam',
      },
      tabs: {
        general: 'General',
        collections: 'Collecties',
        apps: 'Apps',
      },
    },
    diagnostics: {
      fields: {
        created_at: 'Aanmaakdatum',
        data: {
          script: 'Naam',
        },
        identifier: 'Aanduiding',
        type: 'Type',
      },
    },
    flavours: {
      name: 'Doelgroep |||| Doelgroepen',
      fields: {
        color: 'Kleur',
        default_app_collection_id: 'Standaard collectie',
        default_app_collections: 'Standaard collecties',
        name: 'Naam',
        serial: 'Serienummer',
      },
    },
    locales: {
      // https://learn.microsoft.com/nl-nl/partner-center/developer/partner-center-supported-languages-and-locales
      name: 'Cultuur |||| Culturen',
      fields: {
        description: 'Beschrijving',
        name: 'Naam',
      },
    },
    partners: {
      fields: {
        name: 'Naam',
        sales_force_id: 'Salesforce ID',
        client_number: 'Partner ID',
        service_person: 'Contactpersoon',
        service_phone_number: 'Contact telefoonnummer',
      },
      section: {
        general: 'Algemeen',
        partner_groups: 'Partnergroepen',
      },
    },
    partner_groups: {
      name: 'Partnergroep |||| Partnergroepen',
      fields: {
        account_manager: 'Accountmanager',
        name: 'Naam',
        countries: 'Land',
      },
      message: {
        add_content: 'Geef een naam op voor de nieuw gemaakte regio voor deze partnergroep.',
        update_content: 'Werk de gegevens van deze partnergroep bij.',
      },
      empty: 'Deze partner behoort tot geen enkele partnergroep.',
    },
    software_components: {
      name: 'Softwarecomponent |||| Softwarecomponenten',
      fields: {
        name: 'Naam',
        path: 'Pad',
        software_version_id: 'Versie',
      },
    },
    software_releases: {
      name: 'Software-uitgave |||| Software-uitgaven',
      fields: {
        custom: 'Aangepast',
        name: 'Naam',
        software_components: 'Softwarecomponenten',
        software_version_id: 'Versie',
      },
    },
    software_versions: {
      name: 'Softwareversie |||| Softwareversies',
      fields: {
        version: 'Versie',
        software_component_id: 'Softwarecomponent',
        update_channel_ids: 'Updatekanalen',
      },
    },
    units: {
      name: 'Tovertafel |||| Tovertafels',
      fields: {
        configuration_key: 'Configuratiesleutel',
        collections: 'Collecties',
        country_id: 'Land',
        designation_id: 'Toewijzing',
        developer_options: {
          allow_terminal: 'Terminal toestaan',
          api_status: 'API-status',
          debug_panel: 'Debug-paneel',
          design_tools: 'Ontwerptools',
          diagnostics: 'Diagnostiek',
          is_lte_enabled: 'LTE ingeschakeld',
          staging_api: 'Gebruik Staging API',
        },
        firmware_version: 'Firmwareversie',
        hardware_id: 'Hardware',
        hardware_version: 'Hardware versie',
        hostname: 'Hostnaam',
        install_date: 'Installatiedatum',
        language_ids: 'Extra talen',
        last_edit: 'Laatst bewerkt',
        last_online: 'Laatst online',
        locale_id: 'Taal',
        mobile_network: {
          alert_threshold: 'Alarmgrens',
          bandwidth_usage: 'Bandbreedtegebruik',
          bandwidth_limit: 'Bandbreedtelimiet',
          is_lte_enabled: 'LTE ingeschakeld',
          next_reset_time: 'Volgende databundel reset tijd',
          provider: 'Aanbieder',
          sim_id: 'Simkaart ID',
          state: 'Status',
          states: {
            unavailable: 'Onbeschikbaar',
            available: 'Beschikbaar',
            critical: 'Kritiek',
          },
        },
        partner: 'Partner',
        partner_group: 'Partnergroep',
        partner_group_id: 'Partnergroep',
        partner_id: 'Partner',
        serial: 'Serienummer',
        serial_number: 'Serienummer',
        show_uptime: 'Cumulatieve bedrijfstijd weergeven',
        software_version: 'Softwareversie',
        studio_access: 'Tover Lab ingeschakeld',
        update_channel_id: 'Updatekanaal',
      },
      section: {
        access_control: 'Toegangsbeheer',
        app_collections: 'Collecties',
        details: 'Systeemdetails',
        general: 'Algemeen',
        history: 'Geschiedenis',
        localization: 'Lokalisatie',
        partner: 'Partner',
        preferences: 'Voorkeuren',
        purchased_apps: 'Gekochte apps',
        subscriptions: 'Abonnementen',
        trial_app_collections: 'Proefapp collecties',
        trial_apps: 'Proefapps',
      },
      tabs: {
        info: 'Informatie',
        apps: 'Apps',
        configuration: 'Configuratie',
        developer_options: 'Ontwikkelaarsopties',
        diagnostics: 'Diagnostiek',
        simcard: 'Simkaart',
        reports: 'Rapporten',
      },
      show: {
        links: {
          rsh: 'RSH',
          terminal: 'Terminal',
          config: 'Configuratie',
          sim: 'Simkaart',
          refresh_config: 'Ververs configuratie',
          fix_missing_sim: 'Ontbrekende simkaart oplossen',
          refresh_from_ifpanel: 'Ververs vanuit IFPanel',
          fat: 'FAT',
        },
      },
      count: '%{smart_count} Tovertafel |||| %{smart_count} Tovertafels',
      helpers: {
        hostname: 'Hostnaam wordt automatisch gegenereerd voor Pixies',
      },
      reports: {
        eventlog: 'Event log',
        game_played: 'Game played',
        start_date: 'Startdatum',
        end_date: 'Einddatum',
        download: 'Download',
        period: 'Report periode',
      },
    },
    update_channels: {
      name: 'Updatekanaal |||| Updatekanalen',
      fields: {
        name: 'Naam',
        release_now: 'Nu uitgeven',
        released_at: 'Releasetijd',
        short_name: 'Verkorte naam',
        software_release_id: 'Software-uitgave',
      },
      section: {
        general: 'Algemeen',
        release_history: 'Uitgavegeschiedenis',
      },
    },
    dashboard: {
      name: 'Dashboard',
      missing_units_from_elastic: 'Ontbrekende tovertafels in ES',
      add_missing_units: 'Ontbrekende tovertafels toevoegen',
    },
    channel_releases: {
      name: 'Uitgave |||| Uitgaven',
      fields: {
        name: 'Software-uitgave',
        created_at: 'Aanmaakdatum',
        hardware_id: 'Hardware',
        released_at: 'Releasetijd',
        release_now: 'Nu uitgeven',
        software_release_id: 'Software-uitgave',
        update_channel_id: 'Updatekanaal',
      },
      forms: {
        edit: {
          header: 'U kunt hier alleen de releasedatum bewerken.',
        },
        create: {
          header: 'Selecteer de software-uitgave voor dit kanaal en stel de releasedatum in.',
        },
      },
      action: {
        released: 'Uitgegeven',
      },
    },
    users: {
      name: 'Gebruiker |||| Gebruikers',
      fields: {
        name: 'Naam',
        roles: 'Rollen',
      },
    },
    permissions: {
      name: 'Rechten |||| Rechten',
      fields: {
        name: 'Naam',
        description: 'Beschrijving',
      },
      values: {
        'add_units': 'Tovertafels toevoegen',
        'dev_options': 'Ontwikkelaarsopties',
        'filter': 'Filter',
        'manage_apps': 'Apps beheren',
        'manage_builds': 'Builds beheren',
        'manage_collections': 'Collecties beheren',
        'manage_designations': 'Toewijzingen beheren',
        'manage_flavours': 'Aspecten beheren',
        'manage_locales': 'Talen beheren',
        'manage_location_contact': 'Locatiecontact beheren',
        'manage_units': 'Tovertafels beheren',
        'manage_userroles': 'Gebruikersrollen beheren',
        'manage_users': 'Gebruikers beheren',
        'mytover invite admin': 'My Tover uitnodiging beheren - admin',
        'mytover invite user': 'My Tover uitnodiging beheren - gebruiker',
        'offer_trial_apps': 'Proefapps aanbieden',
        'perform_rollback': 'Rollback uitvoeren',
        'remove_units': 'Tovertafels verwijderen',
        'report': 'Rapport',
        'reset_units': 'Tovertafels resetten',
        'view_dashboard': 'Dashboard bekijken',
        'use_toverview': 'ToverView gebruiken',
      },
    },
  },
};
