import CollapsibleEditableArray from 'components/CollapsibleEditableArray';
import { get } from 'lodash';
import {
  ArrayField,
  BooleanInput,
  Edit,
  EditProps,
  FormDataConsumer,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useRecordContext,
} from 'react-admin';

const SysConfigEdit = (props: EditProps) => {
  return (
    <Edit id="undefined">
      <SimpleForm sx={{ width: '100%' }}>
        <CollapsibleEditableArray />
      </SimpleForm>
    </Edit>
  );
};

const SysConfEditField = (props: any) => {
  const { source } = props;
  const record = useRecordContext();
  const value = get(record, source);
  if (Array.isArray(value)) {
    return (
      <ArrayField source={source} label={source} fullWidth>
        <SysConfArrayInput source={source} />
      </ArrayField>
    );
  }

  switch (typeof value) {
    case 'boolean':
      return <BooleanInput source={source} label={source} />;
    case 'string':
    case 'number':
      return <TextInput source={source} label={source} defaultValue={value} fullWidth margin="normal" />;
    case 'object':
      if (value !== null) {
        return Object.entries(value as { [key: string]: any }).map(([key, value]) => (
          <SysConfEditField source={`${source}.${key}`} label={source} />
        ));
      }
      break;
    default:
      return value;
  }
};

const SysConfArrayInput = (props: any) => {
  const { source } = props;
  const record = useRecordContext();
  const value = get(record, source);
  if (value[0] && typeof value[0] === 'object') {
    try {
      return (
        <SimpleFormIterator {...props}>
          <FormDataConsumer>
            {({ scopedFormData }) => {
              return <SysConfEditField source={`${source}`} label={source} />;
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      );
    } catch (e) {}
    return value.map((value: any, key: number) => <SysConfEditField source={`${source}.${key}`} label={source} />);
  }
  return <>empty</>;
};

export default SysConfigEdit;
