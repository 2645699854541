import { FC } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  EditProps,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const AppCreate: FC<EditProps> = () => (
  <Create>
    <SimpleForm>
      <ImageInput source="icon" label="custom.labels.icon">
        <ImageField source="src" />
      </ImageInput>
      <TextInput source="name" validate={required()} />
      <TextInput source="product_id" />
      <BooleanInput source="lab_enabled" />
      <ReferenceInput source="flavour_id" reference="flavours">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="designation_ids" reference="designations">
        <SelectArrayInput source="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default AppCreate;
