import { FC } from 'react';
import { AutocompleteInput, Edit, EditProps, ReferenceInput, required, SimpleForm, TextInput } from 'react-admin';
import { ColorInput } from '../../components/ColorPicker';

export const FlavourEdit: FC<EditProps> = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" validate={required()} helperText={false} />
      <ColorInput source="color" variant="filled" options={{ disableAlpha: true }} />
      <TextInput source="serial" disabled />
      <ReferenceInput source="default_app_collection_id" reference="collections">
        <AutocompleteInput optionText="name" emptyText="None" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
