// src/resources/units/components/DownloadReportsBulkAction.tsx
import { Download } from '@mui/icons-material';
import DateRangeModal from 'components/DateRangeModal';
import { FC, useState } from 'react';
import { Button } from 'react-admin';
import { useReportDownloadHandler } from './useReportDownloadHandler';

const DownloadReportsBulkAction: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDownload = useReportDownloadHandler();

  return (
    <>
      <Button
        label="resources.units.reports.download.bulkButton"
        onClick={handleModalOpen}
        startIcon={<Download />}
        variant="text"
      />
      <DateRangeModal
        open={isModalOpen}
        onClose={handleModalClose}
        onDownload={handleDownload}
        types={['eventlog', 'game_played']}
      />
    </>
  );
};

export default DownloadReportsBulkAction;
