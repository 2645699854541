import { FC } from 'react';
import {
  Datagrid,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from 'react-admin';

import IconField from '../../components/Fields/IconField';
import FlavourChipField from '../flavours/FlavourChipField';
import HardwareIcon from '../hardware/icons/HardwareIcon';
import SurfaceIcon from '../surfaces/icons/SurfaceIcon';

export const PlaylistShow: FC<ShowProps> = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="key" />
      <ReferenceArrayField source="flavour_ids" reference="flavours" emptyText="-">
        <SingleFieldList linkType="show">
          <FlavourChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField source="display_surface_id" reference="display_surfaces" emptyText="-">
        <SurfaceIcon source="name" />
      </ReferenceField>
      <ReferenceArrayField source="hardware_ids" reference="hardware" emptyText="-">
        <SingleFieldList linkType="show">
          <HardwareIcon source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="app_ids" reference="apps" emptyText="-">
        <Datagrid bulkActionButtons={false}>
          <IconField source="icon.src" />
          <TextField source="translated_name" />
          <NumberField source="cognitive" />
          <ReferenceArrayField source="flavour_ids" reference="flavours" emptyText="-">
            <SingleFieldList linkType="show">
              <FlavourChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField source="hardware_ids" reference="hardware" emptyText="-">
            <SingleFieldList linkType="show">
              <HardwareIcon source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField source="display_surface_ids" reference="display_surfaces" emptyText="-">
            <SingleFieldList linkType="show">
              <SurfaceIcon source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Datagrid>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);
