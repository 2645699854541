import { FC } from 'react';
import { Button, ImageField, useRecordContext, useUpdate } from 'react-admin';
import { Card, CardActions, CardContent, Typography } from '@mui/material';

const AppChipField: FC<{ formData?: Record<string, any> }> = ({ formData }) => {
  const record = useRecordContext();

  const diff = {
    ...formData,
    app_ids: formData?.app_ids.filter((id: number) => id !== record?.id),
  };
  const [update, { isLoading }] = useUpdate();
  const handleClick = () => {
    update('collections', {
      id: formData?.id,
      data: diff,
      previousData: formData,
    });
  };

  return (
    <Card sx={{ margin: 1, padding: 0, width: 160, height: 160 }}>
      <CardContent sx={{ padding: 0 }}>
        <ImageField
          source="icon.src"
          sx={{
            '& img': { maxWidth: 100, maxHeight: 100, objectFit: 'contain' },
            'textAlign': 'center',
          }}
        />
        <Typography component="div" sx={{ textAlign: 'center' }}>
          {record?.translated_name}
        </Typography>
      </CardContent>
      {formData && (
        <CardActions>
          <Button label="ra.action.remove" fullWidth disabled={isLoading} onClick={handleClick} />
        </CardActions>
      )}
    </Card>
  );
};

export default AppChipField;
