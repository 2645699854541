import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  EditBase,
  EditProps,
  SimpleForm,
  TextInput,
  Title,
  email,
  required,
  useEditContext,
  useTranslate,
} from 'react-admin';

import { FormToolbar } from 'components/FormToolbar';
import PartnerGroupField from './components/PartnerGroupField';

export const PartnerEdit: FC<EditProps> = (props) => {
  return (
    <EditBase>
      <PartnerEditView />
    </EditBase>
  );
};

const PartnerEditView = (props: any) => {
  const { title } = props;
  const { resource, defaultTitle } = useEditContext();
  const translate = useTranslate();
  return (
    <>
      <Title title={title} defaultTitle={defaultTitle} preferenceKey={`${resource}.edit.title`} />
      <Stack>
        <SimpleForm toolbar={<FormToolbar />} component={Box}>
          <Grid container flexDirection="row">
            <Grid container spacing={4} justifyContent="flex-end" sx={{ mt: 4, zIndex: 1 }} />
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  {translate('resources.partners.section.general')}
                </Typography>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <TextInput source="name" helperText="" validate={required()} fullWidth />
                  <TextInput source="sales_force_id" helperText={false} fullWidth />
                  <TextInput source="client_number" helperText={false} fullWidth />
                  <TextInput source="service_person" helperText={false} fullWidth />
                  <TextInput source="sales_email" type="email" validate={email()} helperText={false} fullWidth />
                  <TextInput source="service_email" type="email" validate={email()} helperText={false} fullWidth />
                  <TextInput source="service_phone_number" helperText={false} fullWidth />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <PartnerGroupField />
              </Grid>
            </Grid>
          </Grid>
        </SimpleForm>
      </Stack>
    </>
  );
};
