import { Refresh } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import {
  Tab,
  TabProps,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';

import { DiagnosticsList } from 'resources/diagnostics/components/DiagnosticsList';

export const DiagnosticsTab = (props: Omit<TabProps, 'children'>) => {
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<string>('');
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const record = useRecordContext();
  const id = record?.id;

  const handleAction = async (action: string, errorMessage: string, successMessage: string) => {
    setLoading(true);
    setAction(action);
    try {
      await dataProvider.post(`units/${id}/${action}`);
      refresh();
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        switch (error.response.status) {
          case 404:
            notify('custom.notification.error.not_found', {
              messageArgs: { item: translate(errorMessage) },
              type: 'error',
            });
            break;
          case 200:
            notify('custom.notification.success', {
              messageArgs: { action: translate(successMessage) },
              type: 'success',
            });
            break;
          default:
            notify('ra.page.error', { type: 'error' });
        }
      }
    } finally {
      setLoading(false);
      setAction('');
    }
  };

  const refreshByIfPanel = async () => {
    await handleAction('load-usage-logs', 'resources.units.show.links.sim', 'ra.action.refresh');
  };

  return (
    <Tab {...props}>
      <TopToolbar>
        {' '}
        <LoadingButton
          sx={{
            'backgroundColor': '#292e5c',
            'color': '#fff',
            '&:hover': { backgroundColor: '#3f4d9e', color: '#fff' },
            '&.Mui-disabled': { color: '#fff' },
          }}
          loading={loading && action === 'refresh-by-ifpanel'}
          loadingPosition="start"
          size="small"
          startIcon={<Refresh />}
          onClick={refreshByIfPanel}
          disabled={loading && action === 'refresh-by-ifpanel'}
        >
          {translate('resources.units.show.links.refresh_from_ifpanel')}
        </LoadingButton>
      </TopToolbar>

      <DiagnosticsList />
    </Tab>
  );
};
