import {
  ArrayField,
  Datagrid,
  DeleteWithConfirmButton,
  Labeled,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Box, Grid, Typography } from '@mui/material';

import DialogContainer from 'components/DialogContainer';
import { FormattedDateField } from 'components/FormattedDateField';
import AddSubItem from 'components/SimpleForm/AddSubtem';
import EditSubItem from 'components/SimpleForm/EditSubItem';
import { AddSoftwareReleaseForm } from './components/AddSoftwareReleaseForm';
import { EditSoftwareReleaseForm } from './components/EditSoftwareReleaseForm';

const RecordActionButtons = () => {
  const record = useRecordContext();
  const released_at: number | null = record?.released_at ? Date.parse(record?.released_at) : null;
  const now = Date.now();
  const t = useTranslate();
  return (
    <Box display="flex" justifyContent="center" p={2}>
      {released_at === null || released_at > now ? <EditSubItem resource="software_releases" /> : null}
      {released_at === null || released_at > now ? (
        <DeleteWithConfirmButton mutationMode="optimistic" redirect={false} />
      ) : null}
      {released_at && released_at <= now ? (
        <Typography variant="body2">{t('resources.channel_releases.action.released')}</Typography>
      ) : null}
    </Box>
  );
};

export const UpdateChannelShow = () => {
  const translate = useTranslate();
  // @ts-ignore
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6">{translate('resources.update_channels.section.general')}</Typography>
        <Grid container spacing={6} mb={2}>
          <Grid item xs={6} md={3}>
            <Labeled>
              <TextField source="name" />
            </Labeled>
          </Grid>
          <Grid item xs={6} md={3}>
            <Labeled>
              <TextField source="short_name" />
            </Labeled>
          </Grid>
          <Grid item xs={6} md={3}>
            <ReferenceField source="hardware_revision_id" reference="hardware_revisions">
              <Labeled>
                <TextField source="name" />
              </Labeled>
            </ReferenceField>
          </Grid>
          <Grid item xs={6} md={3}>
            <ReferenceField source="hardware_revision_id" reference="hardware_revisions">
              <Labeled>
                <ReferenceField source="hardware_id" reference="hardware">
                  <TextField source="name" />
                </ReferenceField>
              </Labeled>
            </ReferenceField>
          </Grid>
        </Grid>

        <Typography variant="h6">{translate('resources.update_channels.section.current_release')}</Typography>
        <ReferenceField source="software_release_id" reference="software_releases" label={false}>
          <Grid container spacing={6} mb={2}>
            <Grid item>
              <Labeled>
                <TextField source="name" component="strong" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="software_components">
                <ArrayField source="software_components" label={false}>
                  <Datagrid bulkActionButtons={false} sx={{ pointerEvents: 'none' }}>
                    <TextField source="name" label="Component" />
                    <ReferenceField source="software_version_id" reference="software_versions">
                      <TextField source="name" />
                    </ReferenceField>
                  </Datagrid>
                </ArrayField>
              </Labeled>
            </Grid>
          </Grid>
        </ReferenceField>

        <Typography variant="h6">{translate('resources.update_channels.section.release_history')}</Typography>
        <DialogContainer
          createForm={<AddSoftwareReleaseForm />}
          editForm={<EditSoftwareReleaseForm />}
          resource="channel_releases"
        >
          <ReferenceArrayField source="channel_release_ids" reference="channel_releases">
            <Box sx={{ pb: 2, display: 'flex', justifyContent: 'center' }}>
              <AddSubItem />
            </Box>
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" />
              <FormattedDateField source="released_at" />
              <RecordActionButtons />
            </Datagrid>
          </ReferenceArrayField>
        </DialogContainer>
      </SimpleShowLayout>
    </Show>
  );
};
