import {
  CheckboxGroupInput,
  Edit,
  Labeled,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useGetList,
  useRecordContext,
} from 'react-admin';

import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { capitalize } from 'lodash';
import { memo } from 'react';
import { Designation, Permission } from 'types';

// TODO: How to determine selected?
const RoleSelect = memo(
  ({
    designation,
    part,
    permissions,
  }: {
    designation: string;
    part: string;
    permissions: Permission[] | undefined;
  }) => {
    const record = useRecordContext();
    const r = new RegExp(`${designation} unit ${part}`);
    const p = permissions?.find((permission) => {
      return r.test(permission.name) && record?.permission_ids?.includes(permission.id);
    });

    //const m = p?.name.match(/read|write|override/g);

    const getId = (name: string) => {
      return permissions?.find((permission) => {
        return r.test(permission.name) && permission.name.includes(name);
      })?.id;
    };
    const permissionKeys = [
      { id: getId('read'), name: 'read' },
      { id: getId('override'), name: 'override' },
      { id: getId('write'), name: 'write' },
      { id: 0, name: 'none' },
    ];
    const defaultValue = p?.id || 0;
    const key = `si-${designation} unit ${part}`;

    return (
      <SelectInput
        label={false}
        source={`matrix_permissions[${designation} unit ${part}]`}
        choices={permissionKeys}
        defaultValue={defaultValue}
        validate={required()}
        key={key}
      />
    );
  },
);

export const RoleEdit = () => {
  // const record = useRecordContext();
  // const parts = ['apps', 'locale', 'flavour', 'designation', 'build', 'serial', 'hostname'];

  const { data: designations, isLoading: designationsLoading } = useGetList<Designation>('designations');
  const { data: permissions, isLoading: permissionsLoading } = useGetList<Permission>('permissions');
  const { data: alone, isLoading: aloneLoading } = useGetList<Permission>('permissions/alone');

  // const [update] = useUpdate();

  // const roleSave = (data: any) => {
  //   if (!designations || !permissions) return;
  //   const permission_ids = [];

  //   for (const key in data.permission_ids) {
  //     data.permission_ids[key] && permission_ids.push(data.permission_ids[key]);
  //   }

  //   data.permission_ids = permission_ids;

  //   update(
  //     'roles',
  //     { id: data.id, data },
  //     {
  //       onSuccess: () => {
  //         //go to the view
  //         window.location.href = '/#/roles';
  //       },
  //     },
  //   );
  // };
  if (designationsLoading || permissionsLoading || aloneLoading) return <CircularProgress />;

  return (
    <Edit>
      <SimpleForm sanitizeEmptyValues>
        <RoleEditForm designations={designations} permissions={permissions} alone={alone} />
      </SimpleForm>
    </Edit>
  );
};

const RoleEditForm = ({
  permissions,
  designations,
  alone,
}: {
  permissions?: Permission[];
  designations?: Designation[];
  alone?: Permission[];
}) => {
  const record = useRecordContext();
  const parts = ['apps', 'locale', 'flavour', 'designation', 'build', 'serial', 'hostname'];
  const ids = record?.permission_ids?.filter((id: any) => alone?.find((permission) => permission.id === id));

  return (
    <>
      <TextInput source="name" />
      <TextInput source="short_description" />
      <TextInput source="description" sx={{ width: '50%' }} />
      {/* FIXME: getToverviewPermissionsList */}
      {/* TODO: fix filter or get by new property: permission_ids --> toverview_permission_ids */}
      <CheckboxGroupInput
        source="ids"
        choices={alone}
        label="Toverview permissions"
        optionText={(record) => `resources.permissions.values.${record.name}`}
        translateChoice={true}
        row={false}
        defaultValue={ids}
        name="simple_permission_ids"
      />

      {/* FIXME: getGeneratedPermissionsList */}
      {/* TODO: new property? generated_permission_ids */}
      <Labeled label="Generated permissions">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {parts.map((part, index) => (
                  <TableCell key={`head-${part}-${index}`}>{capitalize(part)}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {designations?.map((designation, index) => (
                <TableRow key={`body-${designation}-${index}`}>
                  <TableCell>{capitalize(designation.name)}</TableCell>
                  {parts.map((part, index) => (
                    <TableCell key={`body-${part}-${index}`}>
                      <RoleSelect
                        designation={designation.name.toLocaleLowerCase()}
                        part={part}
                        permissions={permissions}
                        key={`roleselect-${part}-${index}`}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Labeled>
    </>
  );
};
