import { DataObjectOutlined } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { FormattedDateField } from 'components/FormattedDateField';
import { FC, useState } from 'react';
import {
  Button,
  Datagrid,
  FunctionField,
  ListContextProvider,
  ListControllerResult,
  ListProps,
  RaRecord,
  TextField,
  WithRecord,
  useDataProvider,
  useListController,
  useRecordContext,
  useTranslate,
} from 'react-admin';

interface DiagnosticDialogProps {
  open: boolean;
  onClose: () => void;
  record?: RaRecord;
}

const queryClient = new QueryClient();

const DiagnosticDialog = ({ open, onClose, record }: DiagnosticDialogProps) => {
  if (!record) return;

  const report = typeof record.data === 'object' ? record.data : JSON.parse(record.data);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{report?.script ?? record.id}</DialogTitle>
      <DialogContent>
        <pre>
          <code>{JSON.stringify(report, null, 2)}</code>
        </pre>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button label="ra.action.cancel" onClick={onClose} variant="text" />
        <Button
          label="custom.actions.copy"
          variant="contained"
          onClick={() => navigator.clipboard.writeText(JSON.stringify(report))}
        />
      </DialogActions>
    </Dialog>
  );
};

export const DiagnosticsList: FC<Omit<ListProps, 'children'>> = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const {
    data: reports,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['diagnostics'],
    queryFn: () => dataProvider.getDiagnostics('units', { serial: record?.serial }),
  });

  const { data: list, total, isLoading: load, error: listError, ...controller } = useListController();
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<RaRecord>();

  if (!reports) return null;

  const openDialog = (record: RaRecord) => {
    setSelectedRecord(record);
    setOpen(true);
  };
  const closeDialog = () => setOpen(false);

  return (
    <QueryClientProvider client={queryClient}>
      <ListContextProvider
        value={
          {
            data: reports.data,
            total: reports.total,
            isLoading,
            error: error instanceof Error ? error : null,
            ...controller,
          } as ListControllerResult
        }
      >
        <Datagrid resource="diagnostics" bulkActionButtons={false}>
          <WithRecord
            label="resources.diagnostics.fields.identifier"
            render={(record) => <TextField source="data.script" emptyText={record.id.toString()} />}
          />
          <FormattedDateField source="created_at" />
          <TextField source="type" />
          <FunctionField
            render={(record: RaRecord) => (
              <Button
                onClick={() => openDialog(record)}
                label={translate('custom.actions.show', { name: 'JSON' })}
                size="small"
                startIcon={<DataObjectOutlined />}
              />
            )}
          />
        </Datagrid>
        <DiagnosticDialog open={open} onClose={closeDialog} record={selectedRecord} />
      </ListContextProvider>
    </QueryClientProvider>
  );
};
