import { FC } from 'react';
import {
  ChipField,
  Datagrid,
  EmailField,
  List,
  ListProps,
  ReferenceArrayField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SingleFieldList,
  TextField,
} from 'react-admin';

const UserListFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="role_ids" reference="roles">
    <SelectInput source="name" optionText={(option) => option.short_description} emptyText="All" />
  </ReferenceInput>,
];

const UserList: FC<ListProps> = () => (
  <List perPage={25} filters={UserListFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <EmailField source="email" emptyText="-" />
      <ReferenceArrayField sortable={false} source="role_ids" reference="roles" emptyText="-">
        <SingleFieldList linkType={false}>
          <ChipField source="short_description" sortable={false} />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

export default UserList;
