import { FC } from 'react';
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  type FieldProps,
  List,
  ListProps,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SingleFieldList,
  TextField,
  useRecordContext,
} from 'react-admin';
import IconField from '../../components/Fields/IconField';
import FlavourChipField from '../flavours/FlavourChipField';
import HardwareIcon from '../hardware/icons/HardwareIcon';
import SurfaceIcon from '../surfaces/icons/SurfaceIcon';

const AppListFilters = [
  <SearchInput key="search" source="q" alwaysOn />,
  <ReferenceInput key="flavour" source="flavours.id" reference="flavours">
    <SelectInput source="name" optionText={<FlavourChipField source="name" />} emptyText="All" />
  </ReferenceInput>,
  <ReferenceArrayInput key="designation" source="designations.id" reference="designations">
    <AutocompleteArrayInput source="name" optionText={(option) => option.name} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput key="hardware" source="hardware.id" reference="hardwares">
    <AutocompleteArrayInput source="name" optionText={(option) => option.name} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput key="display_surface" source="display_surface.id" reference="display_surfaces">
    <AutocompleteArrayInput source="name" optionText={(option) => option.name} />
  </ReferenceArrayInput>,
  <BooleanInput key="lab_enabled" source="lab_enabled" />,
];

export const AppNameField = (props: Omit<FieldProps, 'source'>) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return (
    <>
      {/* eslint-disable-next-line react/style-prop-object */}
      <strong style={{ fontSize: '1rem' }}>{record.translated_name}</strong>
      <br />
      {/* eslint-disable-next-line react/style-prop-object */}
      <small style={{ fontSize: '10px' }}>{record.name}</small>
    </>
  );
};

const AppList: FC<ListProps> = () => (
  <List perPage={25} filters={AppListFilters}>
    <Datagrid
      rowClick="show"
      sx={{
        '& .RaDatagrid-headerCell': {
          'whiteSpace': 'nowrap',
          '&:nth-of-type(2)': { width: 60 },
        },
      }}
      bulkActionButtons={false}
    >
      <IconField source="icon.src" sortable={false} />
      <TextField source="name" component={AppNameField} />
      <NumberField source="cognitive" />
      <ReferenceArrayField source="flavour_ids" reference="flavours" emptyText="-">
        <SingleFieldList linkType="show">
          <FlavourChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <BooleanField source="lab_enabled" />
      <ReferenceArrayField source="display_surface_ids" reference="display_surfaces" emptyText="-" sortable={false}>
        <SingleFieldList linkType="show">
          <SurfaceIcon source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="hardware_ids" reference="hardware" emptyText="-" sortable={false}>
        <SingleFieldList linkType="show">
          <HardwareIcon source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

export default AppList;
