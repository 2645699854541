import BuildIcon from '@mui/icons-material/Build';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { LoadingButton } from '@mui/lab';
import { isAxiosError } from 'axios';
import { usePermission } from 'providers/auth/permissionProvider';
import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, useRefresh, useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';

const MissingUnits = () => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const { hasPermission } = usePermission();

  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState<number>(0);
  const addMissingUnits = () => {
    handleAction('add_missing_units', 'unit', 'unit');
  };

  useEffect(() => {
    dataProvider.get('units/missing_unit_count').then((res: any) => {
      setCount(res.data);
    });
  }, [dataProvider]);

  const handleAction = async (action: string, errorMessage: string, successMessage: string) => {
    setLoading(true);
    try {
      await dataProvider.post(`units/${action}`);
      refresh();
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        switch (error.response.status) {
          case 404:
            notify('custom.notification.error.not_found', {
              messageArgs: { item: translate(errorMessage) },
              type: 'error',
            });
            break;
          case 200:
            notify('custom.notification.success', {
              messageArgs: { action: translate(successMessage) },
              type: 'success',
            });
            break;
          default:
            notify('ra.page.error', { type: 'error' });
        }
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <CardWithIcon
      icon={CrisisAlertIcon}
      title={translate('resources.dashboard.missing_units_from_elastic', {
        _: 'Missing units from ES',
        smart_count: 1,
      })}
      subtitle={count.toString()}
    >
      {!!count && (
        <LoadingButton
          variant="contained"
          loading={loading}
          startIcon={<BuildIcon />}
          onClick={addMissingUnits}
          disabled={loading || !hasPermission('dev_options')}
          size="small"
        >
          {translate('resources.dashboard.add_missing_units')}
        </LoadingButton>
      )}
    </CardWithIcon>
  );
};

export default MissingUnits;
