import { FC } from 'react';
import { Datagrid, List, ReferenceField, ReferenceInput, SearchInput, SelectInput, TextField } from 'react-admin';

const updateChannelFilters = [
  <SearchInput key="searchInput" source="q" alwaysOn />,
  <ReferenceInput key="referenceInput" source="hardware_id" reference="hardware" alwaysOn>
    <SelectInput source="name" />
  </ReferenceInput>,
];

export const UpdateChannelList: FC = () => {
  return (
    <List filters={updateChannelFilters} filterDefaultValues={{ hardware_id: 1 }}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" />
        <ReferenceField source="hardware_revision_id" reference="hardware_revisions">
          {/* ... rest of the code ... */}
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
