import { Avatar, Chip, Paper, Stack, Table, TableCell, TableRow, Typography } from '@mui/material';
import TimeAgoField from 'components/TimeAgoField';
import TruncatedTextField from 'components/TruncatedTextField';
import { get } from 'lodash';
import {
  Datagrid,
  DateField,
  FunctionField,
  Link,
  NumberField,
  RaRecord,
  ReferenceField,
  Show,
  ShowButton,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';

const ListField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  const list = get(record, source, []);
  return list.map((item: any) => <Chip sx={{ m: 1 }} key={`${source}.${item}`} label={item} />);
};

const JsonField = ({ source, ...props }: { source: string }) => {
  const record = useRecordContext();
  const list = get(record, source, []);
  return (
    // <SyntaxHighlighter language="json" style={vscDarkPlus} wrapLongLines {...props}>
    JSON.stringify(list, null, 2)
    // </SyntaxHighlighter>
  );
};
// JsonField.defaultProps = {
//   addLabel: false,
// };

const ExceptionField = () => {
  const record = useRecordContext();
  const data = get(record, 'batches', []);
  const exceptions = data
    .filter((item: any) => {
      return item.type === 'exception';
    })
    .map((item: any) => {
      item.id = item.uuid;
      return item;
    });

  return (
    <Datagrid data={exceptions} total={exceptions.length} isLoading={false} sort={{ field: 'id', order: 'DESC' }}>
      <TextField source="content.class" />
      <TruncatedTextField source="content.message" maxLength={100} label="Message" />
      <ShowButton resource="telescope/exceptions" />
    </Datagrid>
  );
};
const LogField = () => {
  const record = useRecordContext();
  const data = get(record, 'batches', []);
  const exceptions = data
    .filter((item: any) => {
      return item.type === 'log';
    })
    .map((item: any) => {
      item.id = item.uuid;
      return item;
    });

  return (
    <Datagrid data={exceptions} total={exceptions.length} isLoading={false} sort={{ field: 'id', order: 'DESC' }}>
      <TruncatedTextField source="content.message" maxLength={100} label="Message" />
      <ShowButton resource="telescope/logs" />
    </Datagrid>
  );
};

const TelescopeExceptionShow = () => (
  <Show>
    <Stack>
      <Paper sx={{ p: 2 }}>
        <SimpleShowLayout>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Telescope Exception
          </Typography>
          <Table>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>
                <DateField showTime source="created_at" />
                <br />
                <TimeAgoField source="created_at" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hostname</TableCell>
              <TableCell>
                <TextField source="content.hostname" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>
                <TextField source="content.class" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell>
                <TextField source="content.file" />:<NumberField source="content.line" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Occurrences</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Resolved at </TableCell>
              <TableCell>
                <DateField source="content.resolved_at" showTime />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Request</TableCell>
              <TableCell>
                <FunctionField
                  render={(record: RaRecord) => (
                    <Link to={`/telescope/requests/${record?.content?.request_id}`}>
                      <TextField source="content.request_id" />
                    </Link>
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Duration</TableCell>
              <TableCell>
                <TextField source="content.duration" />
              </TableCell>
            </TableRow>{' '}
            <TableRow>
              <TableCell>IP Address</TableCell>
              <TableCell>
                <TextField source="content.ip_address" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Memory usage</TableCell>
              <TableCell>
                <TextField source="content.memory" />
                MB
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tags</TableCell>
              <TableCell>
                <ListField source="tags" />
              </TableCell>
            </TableRow>
          </Table>
        </SimpleShowLayout>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <SimpleShowLayout>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Authenticated User
          </Typography>
          <FunctionField label="Email" render={(record: RaRecord) => <Avatar src={record.content.user.avatar} />} />
          <ReferenceField reference="users" label="Name" source="content.user.id">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField reference="users" label="Email" source="content.user.id">
            <TextField source="email" />
          </ReferenceField>
        </SimpleShowLayout>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <TabbedShowLayout syncWithLocation={false}>
          <TabbedShowLayout.Tab label="Payload">
            <JsonField source="content.payload" />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Headers">
            <JsonField source="content.headers" />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Session">
            <JsonField source="content.session" />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Response">
            <JsonField source="content.response" />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <TabbedShowLayout syncWithLocation={false}>
          <TabbedShowLayout.Tab label="Exceptions">
            <ExceptionField />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Logs">
            <LogField />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Paper>
    </Stack>
  </Show>
);

export default TelescopeExceptionShow;
