import { FC } from 'react';
import { AutocompleteInput, Edit, EditProps, ReferenceInput, SimpleForm, TextInput } from 'react-admin';

export const LocaleEdit: FC<EditProps> = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <ReferenceInput source="fallback_id" reference="locales">
        <AutocompleteInput optionText="name" fullWidth />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
