import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { useDataProvider, useTranslate } from 'react-admin';
import { useEffect, useState } from 'react';
import CardWithIcon from './CardWithIcon';

interface Props {
  value?: number;
}

const CurrentlyConnectedUnits = (props: Props) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [onlineCount, setOnlineCount] = useState<number>(0);

  //get onlinecount from provider and store in onlineCount
  useEffect(() => {
    dataProvider
      .getCurrentlyOnlineCount()
      .then(({ data }: { data: any }) => {
        setOnlineCount(data.socket);
      })
      .catch((error: any) => console.log(error));
  }, [dataProvider]);

  return (
    <CardWithIcon
      to=""
      icon={CloudDoneIcon}
      title={translate('resources.dashboard.currently_online_units', { _: 'Currently Online', smart_count: 1 })}
      subtitle={`${onlineCount}`}
    />
  );
};

export default CurrentlyConnectedUnits;
