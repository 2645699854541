import { Button, useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import { Edit as EditIcon } from '@mui/icons-material';
import useDialogContext from 'components/DialogContainer/useDialogContext';

interface EditSubItemProps {
  label?: string;
  resource?: string;
  childre?: React.ReactElement;
}
const EditSubItem = (props: EditSubItemProps) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const t = useTranslate();
  const { openEditDialog, editForm } = useDialogContext();

  if (!editForm || !record) return null;

  let label;
  try {
    if (props.label) {
      label = props.label;
    } else {
      label = t('custom.actions.edit', {
        name: t(`resources.${resource}.name`, { smart_count: 1 }).replace(/^\w/, (c) => c.toLowerCase()),
        Name: t(`resources.${resource}.name`, { smart_count: 1 }),
      });
    }
  } catch (e) {
    debugger;
  }
  return (
    <Button label={label} onClick={() => openEditDialog(record)}>
      <EditIcon />
    </Button>
  );
};

export default EditSubItem;
