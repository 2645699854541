import Grid2 from '@mui/material/Grid2';

import { FC } from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const AppEdit: FC<EditProps> = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <Grid2 container direction="column">
        <ImageInput source="icon" label="custom.labels.icon">
          <ImageField source="src" />
        </ImageInput>
        <TextInput source="name" />
        <TextInput source="product_id" />
        <BooleanInput source="lab_enabled" />
        <ReferenceArrayInput source="flavour_ids" reference="flavours">
          <SelectArrayInput source="hardware" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="hardware_ids" reference="hardware">
          <SelectArrayInput source="hardware" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="designation_ids" reference="designations">
          <SelectArrayInput source="name" />
        </ReferenceArrayInput>
      </Grid2>
    </SimpleForm>
  </Edit>
);

export default AppEdit;
