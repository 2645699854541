import { FC } from 'react';
import { FormDataConsumer, ReferenceInput, SelectInput, SimpleForm, SimpleFormProps, TextInput } from 'react-admin';

export const UpdateChannelPutForm: FC<Omit<SimpleFormProps, 'children'>> = () => {
  return (
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <TextInput source="short_name" fullWidth />
      <ReferenceInput source="hardware_id" reference="hardware">
        <SelectInput optionText="name" fullWidth />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.hardware_id && (
            <ReferenceInput
              source="hardware_revision_id"
              reference="hardware_revisions"
              filter={{ hardware_id: formData.hardware_id }}
            >
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  );
};
