import { get } from 'lodash';
import { BooleanField, Tab, TabProps, useRecordContext } from 'react-admin';

export const DevTab = (props: Omit<TabProps, 'children'>) => {
  const record = useRecordContext();
  const is_lte_enabled = get(record, 'developer_options.is_lte_enabled');
  return (
    <Tab {...props}>
      <BooleanField source="developer_options.no_getconfig" />
      <BooleanField source="developer_options.debug_panel" />
      <BooleanField source="developer_options.diagnostics" />
      <BooleanField source="developer_options.design_tools" />
      <BooleanField source="developer_options.staging_api" />
      {is_lte_enabled !== null && <BooleanField source="developer_options.is_lte_enabled" />}
    </Tab>
  );
};
