import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';

interface Props {
  value?: number;
}

const LastOnlineUnits = (props: Props) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    dataProvider.get('units/last_online_unit_count').then((res: any) => {
      setCount(res.data);
    });
  });

  return (
    <CardWithIcon
      to=""
      icon={CloudDoneIcon}
      title={translate('resources.dashboard.last_online_units', { _: 'Last online units', smart_count: 1 })}
      subtitle={count.toString()}
    />
  );
};

export default LastOnlineUnits;
