import { FC } from 'react';
import { AutocompleteInput, Create, EditProps, ReferenceInput, required, SimpleForm, TextInput } from 'react-admin';
import { ColorInput } from '../../components/ColorPicker';

export const FlavourCreate: FC<EditProps> = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      {/**
       * react-admin adds additional space for helperText
       * https://github.com/marmelab/react-admin/pull/4364
       * ColorInput does not: set empty helperText
       */}
      <ColorInput source="color" variant="filled" options={{ disableAlpha: true }} helperText=" " />
      <TextInput source="serial" />
      <ReferenceInput source="default_app_collection_id" reference="collections">
        <AutocompleteInput optionText="name" emptyText="None" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
